import {
  CARD_DETAILS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  UPDATE_USER_PIC,
} from "../types";

const initialState = {
  user: null,
  uid: null,
  cardDetails: null,
};

/*
    Any action related to Profile will go here.
*/

export default function authUserReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        uid: payload.id,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        uid: null,
        cardDetails: null,
      };
    case CARD_DETAILS:
      return {
        ...state,
        cardDetails: payload,
      };
    case UPDATE_USER_PIC:
      return {
        ...state,
        user: { ...state.user, imageUrl: payload },
      };
    default:
      return { ...state };
  }
}
