import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Public = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
      <Header />
      <div style={{ flexGrow: 1 }}>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Public;
