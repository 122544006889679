// ImageUpload.js

import React from "react";
import { IoIosCamera } from "react-icons/io";
import placeHolder from "../assets/img/placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../store/actions/userActions";
import { toast } from "react-toastify";
import { Progress } from "reactstrap";

const ProfileImage = ({ imageUrl, userID }) => {
  const { progress } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      if (imageFile.type.startsWith("image/")) {
        dispatch(updateUserInfo(userID, "", imageFile, imageUrl));
      } else {
        toast.warning("Please select only image!");
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center ">
        <div className="position-relative">
          <img
            src={imageUrl || placeHolder}
            className="custom-table__avatar mr-2 border"
          />
          {progress < 1 && (
            <label
              htmlFor="imageInput"
              className="bg-white rounded-circle p-1 border cursor-pointer d-flex justify-content-center align-items-center camera-icon"
            >
              <IoIosCamera />
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </label>
          )}
        </div>
      </div>

      {progress > 0 && (
        // <div className="d-flex justify-content-center align-items-center mt-2 ">
        //   <div
        //     className="w-50 border rounded-pill"
        //     style={{
        //       width: `${progress}%`,
        //       height: "15px",
        //       background: "#16d18f",
        //     }}
        //   >
        //     <p className="fs--9 fw--400 text-white text-center">{progress}%</p>
        //   </div>
        // </div>
        <Progress
          striped
          color="success"
          className="mt-2 profile-progress"
          value={progress}
        />
      )}
    </>
  );
};

export default ProfileImage;
