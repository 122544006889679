import React from "react";
import { useHistory } from "react-router-dom";

const CustomLogo = () => {
  const history = useHistory();
  return (
    <div
      class=" main-logo d-flex cursor-pointer"
      onClick={() => {
        history.push("/");
        window.scrollTo(0, 0);
      }}
    >
      <p className="mb-0 main-logo__t">t</p>
      <p className="mb-0">hessa</p>
      <sup>TM</sup>
    </div>
  );
};

export default CustomLogo;
