import React from "react";
import { Col, Container, FormGroup, Row } from "reactstrap";
import CustomCheckBox from "../common/CustomCheckBox";

const EnterTypeSection = ({ entryType, setEntryType }) => {
  const handleChange = (event) => {
    const value = event.target.name;
    const tempArr = [...entryType];

    if (tempArr.includes(value)) {
      // If the value already exists, remove it
      const updatedArr = tempArr.filter((item) => item !== value);
      setEntryType(updatedArr);
    } else {
      // If the value doesn't exist, add it
      tempArr.push(value);
      setEntryType(tempArr);
    }
  };

  return (
    <Container>
      <Row className="custom-checkbox">
        <Col lg={3} sm={6} xs={12}>
          {" "}
          <FormGroup check>
            <CustomCheckBox
              type={entryType}
              title={"Sole Proprietorship"}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <FormGroup check>
            <CustomCheckBox
              title={"Partnership"}
              type={entryType}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <FormGroup check>
            <CustomCheckBox
              title={"Corporation"}
              type={entryType}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col lg={3} sm={6} xs={12}>
          <FormGroup check>
            <CustomCheckBox
              title={"LLC"}
              type={entryType}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default EnterTypeSection;
