import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

const TradeReferencesSection = ({ tradeReferences, setTradeReferences }) => {
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTradeReferences = [...tradeReferences];
    updatedTradeReferences[index][name] = value;
    setTradeReferences(updatedTradeReferences);
  };
  return (
    <Container>
      <p className="fs--24 fw--500 text-center">
        Trade References: Top 3 Suppliers
      </p>
      {tradeReferences?.map((obj, index) => {
        return (
          <div className="d-flex p-0 m-0">
            <p className="mb-0 px-3">{index + 1}. </p>
            <Row key={index} className=" w-100">
              <Col md={4} sm={12} xs={12}>
                <FormGroup>
                  <Label className="auth__label">Business Name</Label>
                  <Input
                    type="text"
                    placeholder="Business Name"
                    className="text-dark"
                    name="businessName"
                    //required
                    value={obj.businessName}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={12} xs={12}>
                {" "}
                <FormGroup>
                  <Label className="auth__label">A/R Manager</Label>
                  <Input
                    type="text"
                    placeholder="A/R Manager"
                    className="text-dark"
                    name="arManager"
                    //required
                    value={obj.arManager}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </FormGroup>
              </Col>
              <Col md={4} sm={12} xs={12}>
                {" "}
                <FormGroup>
                  <Label className="auth__label">Phone or Email</Label>
                  <Input
                    type="text"
                    placeholder="Phone or Email"
                    className="text-dark"
                    name="phoneOrEmail"
                    //required
                    value={obj.phoneOrEmail}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default TradeReferencesSection;
