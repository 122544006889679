import React, { useEffect } from "react";
import MyProfileForm from "../components/MyProfileForm";
import PaymentDetailsForm from "../components/PaymentDetailsForm";
import { Col, Container, Row } from "reactstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
const stripePromise = loadStripe(process.env.REACT_APP_Stripe_Key);
// "pk_test_51OJcd6IPVf5m9hWnnlFpVd4AN9vkZyS5aX1khUBA0CG6ptQAGOQJBAQsGBFBt3lKX0U3YSiKvVHlm9L6YNwbcQLV009PrwOpc8"

const MyProfile = () => {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.authUser);

  return (
    <Container className="py-md-5 py-3">
      <Row className="justify-content-center">
        <Col md="6">
          <h1 className="text-md-center text-start page-title">Profile</h1>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col md="6">
        </Col>
      </Row> */}
      <Row className="justify-content-center mt-md-3 mt-0">
        <Col md="6">
          <MyProfileForm />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6">
          <Elements stripe={stripePromise}>
            <PaymentDetailsForm />
          </Elements>
        </Col>
      </Row>
      {/* <div className="d-flex flex-column justify-content-center"> */}

      {/* </div> */}
    </Container>
  );
};

export default MyProfile;
