import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { getCities } from "../../store/actions/statesAndCitiesAction";

const NewAccountInfo = ({
  states,
  newAccountInfo,
  setNewAccountInfo,
  handleValidatedInputChange,
}) => {
  const dispatch = useDispatch();

  // console.log("newAccountInfo: ", newAccountInfo);
  // {
  //   businessName: "",
  //   dba: "",
  //   stateofIncorporation: "",
  //   federalTaxID: "",
  //   street: "",
  //   state: "",
  //   city: "",
  //   zipCode: "",
  //   storePhone: "",

  return (
    <Container>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">
              Business Legal Name (the "Application")
            </Label>
            <Input
              type="text"
              placeholder="Business Legal Name "
              // className="text-dark"
              className={`text-dark ${
                newAccountInfo?.businessName.error ? "border-danger" : ""
              }`}
              name="businessName"
              //required
              value={newAccountInfo?.businessName.value}
              onChange={(e) => handleValidatedInputChange(e, setNewAccountInfo)}
            />
            {newAccountInfo?.businessName.error && (
              <small className="text-danger">
                {newAccountInfo?.businessName.msg}
              </small>
            )}
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">d/b/a</Label>
            <Input
              type="text"
              placeholder="d/b/a"
              className="text-dark"
              name="dba"
              //required
              value={newAccountInfo?.dba.value}
              onChange={(e) => handleValidatedInputChange(e, setNewAccountInfo)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup className="">
            <Label className="auth__label">State of Incorporation</Label>

            <div className="w-100 position-relative">
              <Input
                //required
                type="select"
                name="stateOfIncorporation"
                // className="form-control-alternative text-site-grey"
                className={`form-control-alternative text-site-grey ${
                  newAccountInfo?.stateOfIncorporation.error
                    ? "border-danger"
                    : ""
                }`}
                value={newAccountInfo?.stateOfIncorporation.value}
                onChange={(e) =>
                  handleValidatedInputChange(e, setNewAccountInfo)
                }
              >
                <option value="">-Please Select-</option>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Input>
            </div>
            {newAccountInfo?.stateOfIncorporation.error && (
              <small className="text-danger">
                {newAccountInfo?.stateOfIncorporation.msg}
              </small>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="auth__label">Federal Tax ID (EIN)</Label>
            <Input
              type="password"
              placeholder=" Federal Tax ID"
              className="text-dark"
              // className={`text-dark ${
              //   newAccountInfo?.federalTaxID.error ? "border-danger" : ""
              // }`}
              name="federalTaxID"
              //required
              value={newAccountInfo?.federalTaxID.value}
              onChange={(e) => handleValidatedInputChange(e, setNewAccountInfo)}
            />
            {/* {newAccountInfo?.federalTaxID.error && (
              <small className="text-danger">
                {newAccountInfo?.federalTaxID.msg}
              </small>
            )} */}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          {" "}
          <FormGroup>
            <Label className="auth__label">Store Phone</Label>
            <Input
              type="number"
              placeholder="phone"
              name="storePhone"
              // className="text-dark"
              className={`text-dark ${
                newAccountInfo?.storePhone.error ? "border-danger" : ""
              }`}
              //required
              value={newAccountInfo?.storePhone.value}
              onChange={(e) => handleValidatedInputChange(e, setNewAccountInfo)}
            />
            {newAccountInfo?.storePhone.error && (
              <small className="text-danger">
                {newAccountInfo?.storePhone.msg}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default NewAccountInfo;
