import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Row, Spinner } from "reactstrap";
import OrderListing from "../components/order/OrderListing";
import { getAllOrders } from "../store/actions/orderAction";

const OrderList = () => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { orders, isGettingOrders } = useSelector((state) => state.order);
  const [selectedStatus, setSelectedStatus] = useState("All");

  const filteredOrders = orders?.filter((order) => {
    if (selectedStatus === "All") {
      return true;
    }

    const status = order?.history?.[order?.history.length - 1]?.status;

    return status?.toLowerCase() === selectedStatus.toLowerCase();
  });

  useEffect(() => {
    dispatch(getAllOrders(uid));
  }, []);

  return (
    <div className="py-md-5 py-3">
      <Container>
        <p className="text-start page-title mb-3 mb-md-5">Order List</p>

        {isGettingOrders ? (
          <div className="d-flex justify-content-center px-3 py-5">
            <Spinner />
          </div>
        ) : (
          <>
            {orders && orders.length == 0 ? (
              <div className="py-5 d-flex justify-content-center align-items-center">
                <h1>No Order Found</h1>{" "}
              </div>
            ) : (
              <>
                <Row className="mb-3">
                  <Col className="">
                    {" "}
                    <Input
                      type="select"
                      className="w-25 float-right"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      <option value="All">All</option>
                      <option value="Confirmed">Confirmed</option>
                      <option value="Processing">Processed</option>
                      <option value="Dispatched">Dispatched</option>
                      <option value="Completed">Completed</option>
                    </Input>
                  </Col>
                </Row>
                {filteredOrders && filteredOrders.length > 0 ? (
                  filteredOrders?.map((order, idx) => (
                    <OrderListing order={order} idx={idx} key={order.id} />
                  ))
                ) : (
                  <div className="d-flex justify-content-center align-items-center py-5">
                    <h1>No Order Found...!</h1>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {/* <Table className="custom-table" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pricing</th>
              <th>Image</th>
              <th className="text-center">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((order) =>
              order?.products?.map((item, idx) => (
                <tr key={idx}>
                  <td className="text-dark align-middle">{item?.name}</td>
                  <td className="text-dark align-middle">{item?.price}$</td>
                  <td className="align-middle">
                    {" "}
                    <div className="custom-table__img p-1">
                      {" "}
                      <img src={item?.image} />{" "}
                    </div>{" "}
                  </td>
                  <td className="text-center align-middle">{item?.quantity}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table> */}
      </Container>
      {/* <TrackingModal isOpen={modalOpen} toggle={toggleModal} /> */}
    </div>
  );
};

export default OrderList;
