import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

const SupportingDocSection = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <div className="">
            <p className="fs--24">
              Supporting Documents Required{" "}
              <span className="fs--16">(Please do not send originals)</span>
            </p>
            <ul>
              <li>
                Certificate of Formation or Certificate of Good Standing (if
                corporation or LLC)
              </li>
              <li>
                Sales tax registration document (where applicable) or Business
                License
              </li>
              <li>Managing principal’s (owner’s) photo ID</li>
              <li>Photographs of store exterior and interior</li>
              <li>Other documents reasonably requested by STC</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SupportingDocSection;
