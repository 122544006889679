import { Link } from "react-router-dom";

import React from "react";
import Testimonials from "../components/Testimonials";
import { Beauty } from "../components/Beauty";
import ShopNow from "../components/ShopNow";
import ProductDelivery from "../components/ProductDelivery";
import HeroSection from "../components/HeroSection";
import NewHeroSection from "../components/NewHeroSection";

const Home = () => {
  return (
    <>
      <NewHeroSection />
      <ProductDelivery />
      <ShopNow />
      <Beauty />
      {/* <Testimonials /> */}
    </>
  );
};

export default Home;
