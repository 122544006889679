import React, { useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import HorizontalLinearStepper from "../../HorizontalLinearStepper";
import ShowAttachmentModal from "./ShowAttachmentModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const OrderListing = ({ order, idx }) => {
  console.log("order: ", order);

  const isPublic =
    (order?.attachments &&
      order?.attachments.length > 0 &&
      order?.attachments[0].type?.toLowerCase() === "public") ||
    false;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectOrderAttactment, setSelectedAttachment] = useState();
  const toggleModal = () => setModalOpen(!modalOpen);
  const handleAttachements = () => {
    setSelectedAttachment(order?.attachments);
    toggleModal();
  };

  return (
    <div className="mb-3">
      <Row key={idx} className="mb-2">
        <Col lg="3" xl="4" className="">
          <div className="heading_left">
            <h6 className="fw--600 fs--20">
              Order Id: <span className="fw--400 fs--16">{order?.id}</span>
            </h6>
            {order?.trackingDetails?.trackingNumber && (
              <h6 className="fw--600 fs--20">
                Tracking Number:{" "}
                <span className="fw--400 fs--16">
                  {order?.trackingDetails?.trackingNumber}
                </span>
              </h6>
            )}
            {order?.trackingDetails?.trackingUrl && (
              <h6 className="fw--600 fs--20">
                Tracking Url:{" "}
                <a
                  href={order?.trackingDetails?.trackingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fs--16"
                >
                  Visit Tracking Site
                </a>
              </h6>
            )}

            <h6 className="fw--600 fs--20">
              Status:
              <span className="fw--500 fs--14 py-1 px-2 rounded-pill  ml-2 bg-site-primary-dark">
                {order?.status}
              </span>
            </h6>
          </div>
        </Col>
        <Col lg="9" xl="8">
          <HorizontalLinearStepper order={order} />
        </Col>
      </Row>

      <Table className="custom-table " responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Pricing</th>
            <th>Image</th>
            {isPublic && <th>Attachements</th>}
            <th className="text-center">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {order?.products?.map((item, idx) => {
            return (
              <tr key={idx}>
                <td className="text-dark align-middle">{item?.name}</td>
                <td className="text-dark align-middle">
                  {item?.price * item?.quantity}$
                </td>
                <td className="align-middle">
                  {" "}
                  <div className="custom-table__img p-1">
                    {" "}
                    <img src={item?.image} alt="" />{" "}
                  </div>{" "}
                </td>
                {isPublic && (
                  <td className="align-middle text-center">
                    <Button
                      disabled={
                        !order?.attachments || order?.attachments.length === 0
                      }
                      className="bg-site-primry  custom-table__btn px-3  text-dark"
                      onClick={handleAttachements}
                    >
                      See Attachements
                    </Button>
                  </td>
                )}
                <td className="text-center align-middle">{item?.quantity}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <ShowAttachmentModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        attachments={selectOrderAttactment}
      />
    </div>
  );
};

export default OrderListing;
