import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

const steps = [
  "Confirmed",
  "Processing",
  "Dispatched",
  "Delivered",
  "Completed",
];

export default function HorizontalLinearStepper({ order }) {
  const [activeStep, setActiveStep] = useState(0);
  // const [orientation, setOrientation] = React.useState("horizontal");
  const isMobile = useMediaQuery("(max-width: 767.99px)");

  const orientation = isMobile ? "vertical" : "horizontal";

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (order?.status) {
      switch (order?.status) {
        case "confirmed":
          setActiveStep(1);
          break;
        case "processing":
          setActiveStep(2);
          break;
        case "dispatched":
          setActiveStep(3);

          break;
        case "delivered":
          setActiveStep(4);
          break;
        case "completed":
          setActiveStep(5);
          break;
        default:
          break;
      }
    }
  }, [order]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setOrientation(window.innerWidth < 700 ? "vertical" : "horizontal");
  //   };

  //   // Initial setup
  //   handleResize();

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} orientation={orientation}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          //   if (isStepOptional(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <h3
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    marginTop: "10px",
                    marginLeft: "5px",
                  }}
                >
                  {label}
                </h3>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <h5 className="text-success"> Your order has been completed!</h5>
          </Typography>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {/* {activeStep === 0 && <OrderList/>} */}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {/* <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button> */}
            <Box sx={{ flex: "1 1 auto" }} />

            {/* <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> */}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
