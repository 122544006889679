import React, { useEffect, useImperativeHandle, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Cart from "../components/Checkout.js/Cart";
import DetailsAndPayment from "../components/Checkout.js/DetailsAndPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { checkOutOrder, getAllCarts } from "../store/actions/cartAction";
import { getCardDetails } from "../store/actions/paymentCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CART_LOADER } from "../store/types";
import LoadingComponent from "../components/common/LoadingComponent.";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import { stripe_key } from "../constants";
const stripePromise = loadStripe(process.env.REACT_APP_Stripe_Key);

const Checkout = () => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.product);
  const { uid, user, cardDetails } = useSelector((state) => state.authUser);
  const { carts, totalAmount } = useSelector((state) => state.cart);
  const history = useHistory();
  // const [totalPrice, setTotalPrice] = useState(0);
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);
  const [discountDetails, setDiscountDetails] = useState({});
  const [defaultLoader, setDefaultLoader] = useState(true);

  const [currentStep, setCurrentStep] = useState(1);
  const handleNextClick = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const HandleCheckOut = (token) => {
    console.log("token: ", token);
    const payload = {
      userId: uid,
      totalAmount: promoApplied
        ? discountDetails?.discountedAmount
        : totalAmount,
      buyerName: user?.name,
      products: carts[0]?.items,
      wholeSellerName: user?.name,
    };

    if (token?.id) {
      // console.log('token: ', token.id);
      payload.tokenId = token.id;
    }
    if (promoApplied) {
      payload.discount = discountDetails;
    }

    dispatch(
      checkOutOrder(payload, () => {
        history.push("/order-list");
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCarts(uid));
    dispatch(getCardDetails(uid));
    setTimeout(() => {
      setDefaultLoader(false);
    }, 1000);
  }, []);
  return (
    <>
      {loader || defaultLoader ? (
        <LoadingComponent />
      ) : carts.length > 0 ? (
        <div className="checkout py-md-5 py-3">
          <Container>
            {/* Stepper */}
            <div className="p-sm-3 p-2 stepper">
              <Row noGutters>
                <Col xs="6" className="d-flex align-items-center">
                  <div
                    className={`${
                      currentStep == 1 && "stepper__active"
                    } stepper__icon p-1 d-flex justify-content-center align-items-center mr-1 mr-sm-2 mr-md-3 `}
                  >
                    1
                  </div>
                  <p className="m-0 stepper__label text-dark ">
                    Order Overview
                  </p>
                </Col>
                <Col xs="6" className="d-flex align-items-center mt-md-0 mt-2">
                  <div
                    className={`${
                      currentStep == 2 && "stepper__active"
                    } stepper__icon p-1 d-flex justify-content-center align-items-center mr-1 mr-sm-2 mr-md-3`}
                  >
                    2
                  </div>{" "}
                  <p className="m-0  stepper__label text-dark">
                    Details & Payment
                  </p>
                </Col>
              </Row>
            </div>

            {currentStep == 1 && (
              <Cart
                handleNextClick={handleNextClick}
                uid={uid}
                // totalPrice={totalPrice}
                // setTotalPrice={setTotalPrice}
              />
            )}

            {currentStep == 2 && (
              <Elements stripe={stripePromise}>
                <DetailsAndPayment
                  handleNextClick={handleNextClick}
                  HandleCheckOut={HandleCheckOut}
                  promoApplied={promoApplied}
                  setPromoApplied={setPromoApplied}
                  discountDetails={discountDetails}
                  setDiscountDetails={setDiscountDetails}
                />{" "}
              </Elements>
            )}
          </Container>
        </div>
      ) : (
        <Container className="my-5 py-5 d-flex flex-md-row flex-column justify-content-center align-items-center">
          <h1 className="mr-md-2 mr-0 text-center">No Product added Yet!</h1>
          <Link to="/product">
            <h3 className=" mb-0">Go to shop</h3>
          </Link>
        </Container>
      )}
    </>
  );
};

export default Checkout;
