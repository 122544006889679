import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import { getCities, getStates } from "../store/actions/statesAndCitiesAction";
import { getUserDetails, updateUserInfo } from "../store/actions/userActions";
import ProfileImage from "./ProfileImage";

const MyProfileForm = () => {
  const dispatch = useDispatch();
  const { user, uid } = useSelector((state) => state.authUser);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { loader } = useSelector((state) => state.product);

  const { userDetails, loading, isGettingUserDetails, progress } = useSelector(
    (state) => state.user
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
    address: "",
    telephone: "",
    wholeSellerLicense: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    state: "",
    city: "",
    address: "",
    telephone: "",
    wholeSellerLicense: "",
  });

  useEffect(() => {
    let payload = {
      country: "United States",
    };
    dispatch(
      getStates(payload, (states) => {
        setStates(states?.map((state) => state.name));
      })
    );
  }, []);
  useEffect(() => {
    if (formData?.state !== "") {
      let payload = {
        country: "United States",
        state: formData?.state,
      };
      dispatch(
        getCities(payload, (cities) => {
          setCities(cities);
        })
      );
    }
  }, [formData?.state]);

  const handleDetailsChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateUserInfo(uid, formData));
  };

  useEffect(() => {
    dispatch(getUserDetails(uid));
  }, []);

  useEffect(() => {
    if (userDetails?.id) {
      setFormData((prev) => ({
        ...prev,
        name: userDetails?.name || "",
        email: userDetails?.email || "",
        state: userDetails?.state || "",
        city: userDetails?.city || "",
        address: userDetails?.address || "",
        telephone: userDetails?.telephone || "",
        wholeSellerLicense: userDetails?.wholeSellerLicense || "",
      }));
    }
  }, [userDetails]);

  return (
    <>
      {isGettingUserDetails ? (
        <div className="d-flex justify-content-center align-items-center py-5 px-3">
          <Spinner />
        </div>
      ) : (
        <>
          {/* <div className="d-flex justify-content-center align-items-center ">
            <div className="position-relative">
              <img
                src={user?.imgUrl || placeHolder}
                className="custom-table__avatar mr-2 "
              />
              <div className="bg-white rounded-circle p-1 border cursor-pointer d-flex justify-content-center align-items-center  camera-icon" onClick={()=>console.log("djkf")}>
                {" "}
                <IoIosCamera />
              </div>
            </div>
          </div> */}
          <ProfileImage imageUrl={user?.imageUrl} userID={uid} />

          <Form className="w-100 p-3 " onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                pattern="[A-Za-z\s]+"
                title="Name can only contain letters and spaces"
                className={errors.name ? "is-invalid" : ""}
                required
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                pattern="\S+@\S+\.\S+"
                title="Invalid email format"
                disabled
                className={errors.email ? "is-invalid" : ""}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </FormGroup>
            <FormGroup>
              <Label className="auth__label">State</Label>
              <div className="w-100 position-relative">
                <Input
                  required
                  type="select"
                  name="state"
                  className="form-control-alternative text-site-grey"
                  value={formData?.state}
                  onChange={(e) => {
                    handleDetailsChange(e);
                    setFormData((prev) => ({ ...prev, city: "" }));
                  }}
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="auth__label">City</Label>
              <div className="w-100 position-relative">
                <Input
                  required
                  type="select"
                  name="city"
                  disabled={loader}
                  className="form-control-alternative text-site-grey"
                  value={formData?.city}
                  onChange={handleDetailsChange}
                >
                  <option value="">
                    {loader ? "loading..." : "Select City"}
                  </option>
                  {cities.map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="address">Address</Label>
              <Input
                id="address"
                name="address"
                placeholder="address"
                type="text"
                value={formData.address}
                onChange={handleChange}
                className={errors.address ? "is-invalid" : ""}
                required
              />
              {errors.address && (
                <div className="invalid-feedback">{errors.address}</div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="telephone">Telephone</Label>
              <Input
                id="telephone"
                name="telephone"
                placeholder="Telephone"
                type="tel"
                value={formData.telephone}
                onChange={handleChange}
                className={errors.telephone ? "is-invalid" : ""}
                required
              />
              {errors.telephone && (
                <div className="invalid-feedback">{errors.telephone}</div>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="wholeSellerLicense">Wholesaler License</Label>
              <Input
                id="wholeSellerLicense"
                name="wholeSellerLicense"
                placeholder="Wholesaler License"
                type="text"
                value={formData.wholeSellerLicense}
                onChange={handleChange}
                className={errors.wholeSellerLicense ? "is-invalid" : ""}
                required
              />
              {errors.wholeSellerLicense && (
                <div className="invalid-feedback">
                  {errors.wholeSellerLicense}
                </div>
              )}
            </FormGroup>
            <Button
              className="w-100 bg-site-primary text-dark rounded-pill"
              type="submit"
              disabled={loading}
            >
              {loading && progress < 0 ? <Spinner size="sm" /> : "Save"}
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default MyProfileForm;
