import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import {
  CART_ITEM,
  CART_LOADER,
  DELETE_CART_ITEM,
  SET_GET_CART_ITEMS_LOADER,
} from "../types";
import { setLoader } from "./productAction";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

var order = RepositoryFactory.get("order");
let cartcollection = firebase.firestore().collection("carts");

export const setCartLoader = (val) => async (dispatch) => {
  dispatch({ type: CART_LOADER, payload: val });
};

export const addToCart =
  (item, userId, onFinish = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setCartLoader(true));
      const userCartDoc = await cartcollection
        .where("userId", "==", userId)
        .get();

      if (!userCartDoc.empty) {
        const cartData = userCartDoc.docs[0].data();
        const { items } = cartData;

        if (items.some((cartItem) => cartItem.productId === item.productId)) {
          toast.warn("Product is already in the cart");
          return;
        }

        await cartcollection.doc(userCartDoc.docs[0].id).update({
          items: [...items, item],
        });
      } else {
        await cartcollection.add({
          userId: userId,
          items: [item],
        });
      }
      toast.success("Product added to the cart successfully");
    } catch (error) {
      console.error("Error adding item to cart:", error);
      toast.error("Error adding item to the cart");
    } finally {
      onFinish();

      dispatch(setCartLoader(false));
    }
  };
export const setGetCartLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_GET_CART_ITEMS_LOADER, payload: val });
};

export const getAllCarts = (uid) => async (dispatch) => {
  dispatch(setGetCartLoading(true));
  try {
    await cartcollection.where("userId", "==", uid).onSnapshot((query) => {
      const carts = [];

      // docs.forEach((doc) => {
      //   carts.push({
      //     id: doc.id,
      //     ...doc.data(),
      //   });
      // });

      for (let doc of query.docs) {
        carts.push({
          id: doc.id,
          ...doc.data(),
        });
      }
      dispatch({
        type: CART_ITEM,
        payload: carts,
      });
      dispatch(setGetCartLoading(false));
    });
  } catch (error) {
    console.error(error);
    dispatch(setGetCartLoading(false));
  }
};

export const deleteCartItem =
  (userId, productId, onFinish = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setCartLoader(true));

      const userCartDoc = await cartcollection
        .where("userId", "==", userId)
        .get();

      if (!userCartDoc.empty) {
        const cartData = userCartDoc.docs[0].data();
        const { items } = cartData;

        const updatedItems = items.filter(
          (item) => item.productId !== productId
        );

        if (updatedItems.length == 0) {
          await cartcollection.doc(userCartDoc.docs[0].id).delete();
        } else {
          await cartcollection.doc(userCartDoc.docs[0].id).update({
            items: updatedItems,
          });
        }

        toast.success("Product removed from cart successfully!");
      } else {
        toast.error("User's cart not found");
      }
      onFinish();
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Error deleting product");
    } finally {
      onFinish();
      dispatch(setCartLoader(false));
    }
  };
export const checkOutOrder = (payload, onSuccess) => async (dispatch) => {
  try {
    let { data } = await order.checkout(payload);

    if (data.success) {
      dispatch({ type: CART_LOADER, payload: false });
      toast.success(data.message);
      onSuccess();
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    dispatch({ type: CART_LOADER, payload: false });
  }
};
