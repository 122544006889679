export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const ALL_PRODUCTS = "ALL_PRODUCTS";
export const PRODUCT_LOADER = "PRODUCT_LOADER";
export const ALL_ORDERS = "ALL_ORDERS";
export const ORDER_LOADER = "ORDER_LOADER";
export const CART_ITEM = "CART_ITEM";
export const CART_LOADER = "CART_LOADER";
export const DELETE_CART_ITEM = "DELETE_CART_ITEM";
export const SINGLE_PRODUCT = "SINGLE_PRODUCT";
export const ALL_USERS = "ALL_USERS";

export const QUANTITY_CHANGE = "QUANTITY_CHANGE";
export const UPDATE_TOTAL = "UPDATE_TOTAL";
export const CARD_DETAILS = "CARD_DETAILS";
export const SET_CARD_DETAILS_LOADING = "SET_CARD_DETAILS_LOADING";
export const SET_ADD_CARD_DETAILS_LOADING = "SET_ADD_CARD_DETAILS_LOADING";
export const SET_GET_PRODUCTS_LOADER = "SET_GET_PRODUCTS_LOADER";

export const SET_GET_CART_ITEMS_LOADER = "SET_GET_CART_ITEMS_LOADER";
export const SET_GET_USER_DETAILS_LOADER = "SET_GET_USER_DETAILS_LOADER";
export const SET_SINGLE_USER_DETAILS = "SET_SINGLE_USER_DETAILS";
export const SET_SINGLE_USER_LOADING = "SET_SINGLE_USER_LOADING";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_LAST_VISIBLE = "SET_LAST_VISIBLE";
export const CLEAR_USERS = "CLEAR_USERS";
export const SET_GET_MORE_PRODUCTS_LOADER = "SET_GET_MORE_PRODUCTS_LOADER";
export const GET_MORE_PRODUCTS = "GET_MORE_PRODUCTS";
export const SET__PRODUCTS_LAST_VISIBLE = "SET__PRODUCTS_LAST_VISIBLE";
export const SET_CONTACT_US_LOADER = "SET_CONTACT_US_LOADER";
export const SET_DASHBOARD_LOADING = "SET_DASHBOARD_LOADING";
export const SET_DASHBOARD_STATS = "SET_DASHBOARD_STATS";
export const SET_PROGRESS_BAR = "SET_PROGRESS_BAR";
export const UPDATE_USER_PIC = "UPDATE_USER_PIC";
export const ALL_COLORS = "ALL_COLORS";
export const COLORS_LOADER = "COLORS_LOADER";
