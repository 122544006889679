import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Row } from "reactstrap";
import { getCities, getStates } from "../store/actions/statesAndCitiesAction";
import { getAllUser } from "../store/actions/userActions";
import { CLEAR_USERS } from "../store/types";

const SellerSearch = ({ title }) => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.product);
  const { isGettingUserDetails, lastVisible } = useSelector(
    (state) => state.user
  );
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [selectedCity, setSelectedCity] = useState("");

  const handledSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: CLEAR_USERS,
      payload: [],
    });
    dispatch(getAllUser(lastVisible, selectedState, selectedCity));
  };

  useEffect(() => {
    let payload = {
      country: "United States",
    };
    dispatch(
      getStates(payload, (states) => {
        setStates(states?.map((state) => state.name));
      })
    );
  }, []);
  useEffect(() => {
    if (selectedState !== "") {
      let payload = {
        country: "United States",
        state: selectedState,
      };
      dispatch(
        getCities(payload, (cities) => {
          setCities(cities);
        })
      );
    }
  }, [selectedState]);
  return (
    <Form onSubmit={handledSubmit}>
      <Row className="justify-content-center search">
        <Col xs="12">
          <p className="text-center text-dark search__title">{title}</p>
        </Col>
        <Col md="4" lg="3">
          {/* <Input type="select">
          <option>STATE</option>
        </Input> */}
          <Input
            required
            type="select"
            name="state"
            className="form-control-alternative text-site-grey"
            value={selectedState}
            onChange={(e) => {
              setSelectedState(e.target.value);
            }}
          >
            <option value="">Select State</option>
            {states?.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Input>
        </Col>
        <Col md="4" lg="3" className="mt-md-0 mt-2">
          {/* <Input type="select">
          <option>CITY</option>
        </Input> */}
          <Input
            type="select"
            name="city"
            disabled={loader || !selectedState}
            className="form-control-alternative text-site-grey"
            value={selectedCity}
            onChange={(e) => {
              setSelectedCity(e.target.value);
            }}
          >
            <option value="">{loader ? "loading..." : "Select City"}</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </Input>
        </Col>
        <Col md="4" lg="3" className="mt-md-0 mt-2">
          <Button
            disabled={isGettingUserDetails}
            type="submit"
            className="w-100 bg-site-primary-dark text-dark border-0 rounded-0"
          >
            {isGettingUserDetails ? "Fetching..." : "Search"}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SellerSearch;
