import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import {
  ALL_ORDERS,
  CARD_DETAILS,
  SET_ADD_CARD_DETAILS_LOADING,
  SET_CARD_DETAILS_LOADING,
} from "../types";

var card = RepositoryFactory.get("card");
export const setGetCardDetailsLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_CARD_DETAILS_LOADING, payload: val });
};
export const setAddCardLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_ADD_CARD_DETAILS_LOADING, payload: val });
};

export const getCardDetails =
  (uid, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(setGetCardDetailsLoading(true));
      let { data } = await card.getCardDetails(uid);

      if (data.success) {
        dispatch({ type: CARD_DETAILS, payload: data.data.data[0] });
        onSuccess(data.data.data[0]);
      }
      dispatch(setGetCardDetailsLoading(false));
    } catch (error) {
      dispatch({ type: CARD_DETAILS, payload: null });
      dispatch(setGetCardDetailsLoading(false));

      console.error(error);
    }
  };

export const createCard =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setAddCardLoading(true));
    try {
      let { data } = await card.addCard(payload);

      if (data.success) {
        onSuccess();
        toast.success("Card updated successfully!");
      }
      dispatch(setAddCardLoading(false));
    } catch (error) {
      dispatch(setAddCardLoading(false));
      toast.error(error?.response?.data?.message || "unable to add card");
    }
  };
