import Repository1 from "./Repository1";
const states = "/states";
const cities = "/state/cities";
export default {
  getStates(payload) {
    return Repository1.post(`${states}`, payload);
  },
  getCities(payload) {
    return Repository1.post(`${cities}`, payload);
  },
};
