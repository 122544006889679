import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ReCAPTCHA_sitekey } from "../../constants";
import SignaturePad from "react-signature-canvas";
import { useRef } from "react";
import { GrClear } from "react-icons/gr";

const ApplicantSection = ({
  applicantDetails,
  setApplicantDetails,
  handleInputChange,
}) => {
  const sigCanvas = useRef({});
  const [signatureDataURL, setSignatureDataURL] = useState("");

  const onChangeRecaptcha = (value) => {
    console.log("onChangeRecaptcha", JSON.stringify(value));
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setApplicantDetails((prev) => ({ ...prev, signature: "" }));
      // setSignatureDataURL("");
    }
  };

  const getSignatureValue = async () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();

      let signatureFile = await dataURItoBlob(dataURL);
      // console.log("Signature: ", dataURL);
      setApplicantDetails((prev) => ({ ...prev, signature: signatureFile }));
      return dataURL;
    }
    return "";
  };
  async function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = "png";

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  return (
    <Container className="px-md-2 px-0 text-responsive  ">
      <p className="px-md-4 px-0">
        I hereby certify, on behalf of Applicant, that the information in this
        application is complete and accurate and that I am an authorized
        signatory of Applicant. By submitting this application, Applicant hereby
        agrees to STC’s Terms and Conditions of Sale, as amended by STC from
        time to time. STC may contact the business and financial references
        listed above for credit evaluation purposes, and I, on behalf of
        Applicant, hereby authorize those references to release all information
        that may assist STC’s review. I, on behalf Applicant, acknowledge and
        understand that this application is subject to approval and acceptance
        by STC, STC has sole discretion to accept or reject the application, and
        that STC has no obligation to sell any products to Applicant or to
        provide credit terms to Applicant.
      </p>
      <h5 className="mt-5">APPLICANT</h5>
      <Row>
        <Col md={4} xs={12}>
          <FormGroup>
            <Label className="auth__label">Name</Label>
            <Input
              type="text"
              placeholder="Name"
              className={`text-dark ${
                applicantDetails.name == "error" ? "border-danger" : ""
              }`}
              name="name"
              // required
              value={
                applicantDetails.name == "error" ? "" : applicantDetails.name
              }
              onChange={(e) => handleInputChange(e, setApplicantDetails)}
            />
            {applicantDetails.name == "error" && (
              <small className="text-danger">This field is required*</small>
            )}
          </FormGroup>
        </Col>
        <Col md={4} xs={12}>
          <FormGroup>
            <Label className="auth__label">Title</Label>
            <Input
              type="text"
              placeholder="Title"
              className="text-dark"
              name="title"
              value={applicantDetails.title}
              onChange={(e) => handleInputChange(e, setApplicantDetails)}
            />
          </FormGroup>
        </Col>
        <Col md={4} xs={12}>
          <FormGroup>
            <Label className="auth__label">Date</Label>
            <Input
              type="date"
              placeholder="store size"
              className="text-dark"
              name="date"
              //required
              value={applicantDetails.date}
              onChange={(e) => handleInputChange(e, setApplicantDetails)}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="w-100 d-flex flex-column align-items-center mt-4 ">
        <div className="d-flex  w-100   justify-content-between align-items-center my-2">
          <h5 className="mb-0 text-muted">Signature</h5>
          <button
            color="danger"
            className="border-0 bg-danger px-3  text-white py-2"
            outline
            onClick={clearSignature}
          >
            <div className="d-flex justify-content-around align-items-center  ">
              <GrClear />
              <p className="fw--500  mb-0 ml-2">Clear</p>
            </div>
          </button>
        </div>
        <div className="w-75">
          <SignaturePad
            ref={sigCanvas}
            onEnd={getSignatureValue}
            penColor="black"
            canvasProps={{
              height: 300,
              style: {
                width: "100%",
                border:
                  applicantDetails.signature == "error"
                    ? "1px solid red"
                    : "1px solid grey",
              },
              // className: "signature-responsive",
            }}
          />
        </div>
        {applicantDetails.signature == "error" && (
          <small className="text-danger">signature required*</small>
        )}
      </div>
      {/* <div className={`d-flex justify-content-center mt-4 `}>
        {" "}
        <ReCAPTCHA
          onChange={onChangeRecaptcha}
          sitekey={process.env.REACT_APP_ReCAPTCHA_sitekey}
        />
        {}
      </div> */}
    </Container>
  );
};

export default ApplicantSection;
