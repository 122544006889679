import {
  ALL_COLORS,
  ALL_PRODUCTS,
  COLORS_LOADER,
  GET_MORE_PRODUCTS,
  PRODUCT_LOADER,
  SET_GET_MORE_PRODUCTS_LOADER,
  SET_GET_PRODUCTS_LOADER,
  SET__PRODUCTS_LAST_VISIBLE,
  SINGLE_PRODUCT,
} from "../types";

const initialState = {
  products: [],
  singleProduct: {},
  loader: false,
  isGettingProducts: false,
  isGettingMoreProducts: false,
  lastVisible: null,
  colors: [],
  colorLoader: false,
};

/*
    Any action related to Profile will go here.
*/

export default function productReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ALL_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case GET_MORE_PRODUCTS:
      return {
        ...state,
        products: state.products?.concat(payload),
      };
    case SINGLE_PRODUCT:
      return {
        ...state,
        singleProduct: payload,
      };
    case PRODUCT_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case SET_GET_PRODUCTS_LOADER:
      return {
        ...state,
        isGettingProducts: payload,
      };
    case SET_GET_MORE_PRODUCTS_LOADER:
      return {
        ...state,
        isGettingMoreProducts: payload,
      };
    case SET__PRODUCTS_LAST_VISIBLE:
      return {
        ...state,
        lastVisible: payload,
      };
    case ALL_COLORS:
      return {
        ...state,
        colors: payload,
      };
    case COLORS_LOADER:
      return {
        ...state,
        colorLoader: payload,
      };

    default:
      return { ...state };
  }
}
