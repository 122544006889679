// Header.jsx
import React, { useEffect, useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import logo from "../assets/img/logo.png";
import search from "../assets/img/search.png";
import { login, logout } from "../store/actions/authAction";
import { getAllCarts } from "../store/actions/cartAction";
import placeHolder from "../assets/img/placeholder.jpg";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Media,
} from "reactstrap";
import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import CustomLogo from "./CustomLogo";

const Header = () => {
  const history = useHistory();

  const { user, uid } = useSelector((state) => state.authUser);
  const { carts } = useSelector((state) => state.cart);
  // const [activeLink, setActiveLink] = useState("");

  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { pathname } = window.location;

  // console.log(pathname)
  // console.log("activeLink >>> ", activeLink);
  // const handleLinkClick = (link) => {
  //   console.log("link>>> ", link);
  //   setActiveLink(link);
  // };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleRedirect = (url) => {
    history.push(url);
  };
  const handleLogout = () => {
    dispatch(logout());
    handleRedirect("");
  };

  const CartOption = () => {
    return (
      // <Link to="/checkout">
      <li
        className="main-header__item text-dark main-header__search"
        onClick={() => handleRedirect("/checkout")}
      >
        <div className="position-relative cursor-pointer">
          <div className="fs--18">
            <FaCartShopping />
          </div>
          {carts && carts.length > 0 && (
            <span
              className="position-absolute bg-danger   rounded-circle text-white fs--8 text-center"
              style={{ left: 10, top: -2, height: "13px", width: "13px" }}
            >
              {carts[0].items?.length}
            </span>
          )}
        </div>
      </li>
      // </Link>
    );
  };
  useEffect(() => {
    dispatch(getAllCarts(uid));
  }, []);

  return (
    <div className={`main-header ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <Container>
        <div className="main-header__content">
          <div className="d-flex flex-column justify-content-center">
            <CustomLogo />

            {/* <span className="main-header__description">
              100% kanekalon braiding hair
            </span> */}
          </div>
          {/* <div className="main-header__logo">
            <img
              src={logo}
              alt="logo"
              className="cursor-pointer"
              onClick={() => {
                handleRedirect("/");
              }}
            />
          </div> */}
          <ul className="main-header__right d-lg-flex d-none">
            <li className={`main-header__item fBold`}>
              <Link
                to="/"
                className={`text-dark fBold  ${
                  pathname === "/" ? "active" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li className={`main-header__item fBold`}>
              <Link
                to="/product"
                className={`text-dark fBold ${
                  pathname === "/product" ? "active" : ""
                }`}
              >
                Products
              </Link>
            </li>
            <li className={`main-header__item fBold`}>
              <Link
                to="/retailers"
                className={`text-dark fBold ${
                  pathname === "/retailers" ? "active" : ""
                }`}
              >
                Locate Retailers
              </Link>
            </li>
            {uid && (
              <li className={`main-header__item fBold`}>
                <Link
                  to="/dashboard"
                  className={`text-dark fBold ${
                    pathname === "/dashboard" ? "active" : ""
                  }`}
                >
                  Dashboard
                </Link>
              </li>
            )}
            {uid && (
              <li className={`main-header__item fBold`}>
                <Link
                  to="/order-list"
                  className={`text-dark fBold ${
                    pathname === "/order-list" ? "active" : ""
                  }`}
                >
                  Orders
                </Link>
              </li>
            )}

            <li className={`main-header__item fBold`}>
              <Link
                to="/about-us"
                className={`text-dark fBold ${
                  pathname === "/about-us" ? "active" : ""
                }`}
              >
                About Us
              </Link>
            </li>

            <li className={`main-header__item fBold`}>
              <Link
                to="/contact-us"
                className={`text-dark fBold ${
                  pathname === "/contact-us" ? "active" : ""
                }`}
              >
                Contact Us
              </Link>
            </li>

            {!uid && (
              <li className="main-header__item text-dark">
                <button
                  className=" rounded-0 text-dark border-0 main-header__btn"
                  onClick={() => handleRedirect("/login")}
                >
                  Access Now
                </button>
              </li>
            )}
            {/* <li className="main-header__item text-dark ">
                <Link to="/login"> Signup/login</Link>
              </li> */}

            {uid && CartOption()}

            {/* <li className="main-header__item text-dark">
              <Link to="/contactus">avatar</Link>
            </li> }

            {uid ? (
              <>
                <li className="main-header__item  mb-2">{CartOption()}</li>
                <li
                  className="main-header__item text-dark "
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </>
              ) : (
              <li className="main-header__item text-dark ">
                <Link to="/login"> Signup/login</Link>
              </li>
            )}

            {<li className="main-header__item text-dark">
              <button className=" rounded-0 text-dark border-0 main-header__btn">
                Access Now
              </button>
            </li> */}

            <li
              className="main-header__item text-dark main-header__search "
              onClick={() => {
                handleRedirect("/retailers");
              }}
            >
              <img src={search} className="img-fluid" alt="search" />
            </li>

            {uid ? (
              <li className="main-header__item main-header__profile">
                <UncontrolledDropdown nav>
                  <DropdownToggle className="p-0 m-0" nav>
                    <Media className="align-items-center">
                      <img
                        height={35}
                        width={35}
                        className="rounded-circle"
                        alt="..."
                        src={user?.imageUrl || placeHolder}
                      />
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem to="/my-profile" tag={Link}>
                      <li className="text-dark ">
                        <Link to="/my-profile">My Profile</Link>
                      </li>
                    </DropdownItem>
                    <Divider />
                    <DropdownItem
                      to="/login"
                      tag={Link}
                      onClick={handleLogout}
                      className="mt-2 "
                    >
                      <i className="ni ni-user-run" />
                      <li className="text-dark ">
                        <Link
                          to="/signup"
                          // onClick={() => handleLinkClick("home")}
                        >
                          Logout
                        </Link>
                      </li>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            ) : null}
          </ul>

          <ul className="main-header__right d-lg-none d-flex">
            {uid && <li className="mr-3 mt-2">{CartOption()}</li>}
            <li
              className="main-header__item text-dark main-header__list-icon p-1 d-flex justify-content-center align-items-center "
              onClick={toggleSidebar}
            >
              <i className="fa fa-list"></i>
            </li>
          </ul>
        </div>
      </Container>

      {/* Sidebar Content */}

      {isSidebarOpen && (
        <>
          <div className="sidebar-overlay" onClick={toggleSidebar}></div>
        </>
      )}

      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        {isSidebarOpen && (
          <ul className="main-header__right flex-column">
            <li className=" text-dark d-flex w-100 justify-content-end ">
              <i
                className="fa fa-times text-dark  cursor-pointer"
                onClick={toggleSidebar}
              ></i>
            </li>

            {uid ? (
              <li>
                <Media className="align-items-center">
                  <img
                    height={35}
                    width={35}
                    className="rounded-circle"
                    alt="..."
                    src={user?.imageUrl || placeHolder}
                  />
                </Media>
              </li>
            ) : null}
            {uid ? (
              <li
                className="main-header__item text-dark "
                onClick={() => {
                  toggleSidebar();
                  handleRedirect("/my-profile");
                }}
              >
                My Profile
              </li>
            ) : null}

            <li
              className="main-header__item text-dark "
              onClick={() => {
                toggleSidebar();
                handleRedirect("/");
              }}
            >
              Home
            </li>
            <li
              className="main-header__item text-dark "
              onClick={() => {
                toggleSidebar();
                handleRedirect("/product");
              }}
            >
              Products
            </li>
            <li
              className="main-header__item text-dark "
              onClick={() => {
                toggleSidebar();
                handleRedirect("/retailers");
              }}
            >
              Locate Retailers
            </li>
            {uid && (
              <li
                className="main-header__item text-dark "
                onClick={() => {
                  toggleSidebar();
                  handleRedirect("/dashboard");
                }}
              >
                Dashboard
              </li>
            )}

            {uid && (
              <li
                className="main-header__item text-dark "
                onClick={() => {
                  toggleSidebar();
                  handleRedirect("/order-list");
                }}
              >
                Orders
              </li>
            )}

            <li
              className="main-header__item text-dark "
              onClick={() => {
                toggleSidebar();
                handleRedirect("/about-us");
              }}
            >
              About Us
            </li>
            <li
              className="main-header__item text-dark  "
              onClick={() => {
                toggleSidebar();
                handleRedirect("/contact-us");
              }}
            >
              Contact Us
            </li>

            {uid ? (
              <li
                className="main-header__item text-dark "
                onClick={() => {
                  toggleSidebar();
                  handleLogout();
                }}
              >
                Logout
              </li>
            ) : (
              <li
                className="main-header__item text-dark "
                onClick={() => {
                  toggleSidebar();
                  handleRedirect("/signup");
                }}
              >
                Signup/login
              </li>
            )}

            <li
              className="main-header__item text-dark main-header__search"
              onClick={() => {
                toggleSidebar();
                handleRedirect("/retailers");
              }}
            >
              <img src={search} className="img-fluid" alt="search" />
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Header;
