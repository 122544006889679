import {
  ALL_USERS,
  CLEAR_USERS,
  SET_CONTACT_US_LOADER,
  SET_CURRENT_PAGE,
  SET_GET_USER_DETAILS_LOADER,
  SET_LAST_VISIBLE,
  SET_PROGRESS_BAR,
  SET_SINGLE_USER_DETAILS,
  SET_SINGLE_USER_LOADING,
} from "../types";

const initialState = {
  userDetails: {},
  isGettingUserDetails: false,
  loading: false,
  allUsers: [],
  currentPage: null,
  lastVisible: null,
  isSendingQuery: false,
  progress: 0,
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SINGLE_USER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_SINGLE_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };
    case SET_GET_USER_DETAILS_LOADER:
      return {
        ...state,
        isGettingUserDetails: payload,
      };
    case ALL_USERS:
      return {
        ...state,
        allUsers: [...state.allUsers, ...payload],
      };
    case CLEAR_USERS:
      return {
        ...state,
        allUsers: payload,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case SET_LAST_VISIBLE:
      return {
        ...state,
        lastVisible: payload,
      };

    case SET_CONTACT_US_LOADER:
      return {
        ...state,
        isSendingQuery: payload,
      };
    case SET_PROGRESS_BAR:
      return {
        ...state,
        progress: payload,
      };

    default:
      return { ...state };
  }
}
