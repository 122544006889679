// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import authReducer from "./authReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";
import cartReducer from "./cartReducer";
import userReducer from "./userReducer";
import dashboardReducer from "./dashboardReducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  auth: authReducer,
  product: productReducer,
  order: orderReducer,
  cart: cartReducer,
  user: userReducer,
  dashboard: dashboardReducer,
});

export default rootReducer;
