import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { setLoader } from "./productAction";

var statesAndCities = RepositoryFactory.get("statesAndCities");
export const getStates = (payload, onSuccess) => async (dispatch) => {
  try {
    let { data } = await statesAndCities.getStates(payload);

    if (data.error) {
      console.error(data.msg);
    } else {
      onSuccess(data?.data?.states);
    }
  } catch (error) {
    console.error("error: ", error);
  }
};
export const getCities = (payload, onSuccess) => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    let { data } = await statesAndCities.getCities(payload);

    if (data.error) {
      console.error(data.msg);
    } else {
      onSuccess(data?.data);
    }
  } catch (error) {
    console.error("error: ", error);
  } finally {
    dispatch(setLoader(false));
  }
};
