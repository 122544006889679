import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Form, FormGroup, Label, Input, Button, Spinner } from "reactstrap";
import VISA from "../assets/img/visa.svg";
import MASTERCARD from "../assets/img/MasterCard_Logo.svg.png";
import AMERICANEXPRESS from "../assets/img/americanExpress.png";
import DISCOVER from "../assets/img/Discover-logo.png";
import UNIONPAY from "../assets/img/UnionPay.png";
import DINERSCLUB from "../assets/img/DindersClub.svg";
import JCB from "../assets/img/JCBNEW.png";
import { useDispatch, useSelector } from "react-redux";
import { createCard, getCardDetails } from "../store/actions/paymentCard";

const PaymentDetailsForm = () => {
  const stripe = useStripe();
  const { uid, cardDetails } = useSelector((state) => state.authUser);
  const { isGettingCardDetails, isAddingCard } = useSelector(
    (state) => state.auth
  );
  const [isEditing, setIsEditing] = useState(true);
  const [cardName, setCardName] = useState("");
  const dispatch = useDispatch();

  const elements = useElements();
  const [formData, setFormData] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const [errors, setErrors] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Validation
  //   let isValid = true;

  //   // Validation for Card Number
  //   if (!formData.cardNumber.trim()) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       cardNumber: "Card Number is required",
  //     }));
  //     isValid = false;
  //   } else if (!/^\d{16}$/.test(formData.cardNumber.trim())) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       cardNumber: "Invalid Card Number format",
  //     }));
  //     isValid = false;
  //   }

  //   // Validation for Expiry Date
  //   if (!formData.expiryDate.trim()) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       expiryDate: "Expiry Date is required",
  //     }));
  //     isValid = false;
  //   } else if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(formData.expiryDate.trim())) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       expiryDate: "Invalid Expiry Date format (MM/YYYY)",
  //     }));
  //     isValid = false;
  //   }

  //   // Validation for CVV
  //   if (!formData.cvv.trim()) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       cvv: "CVV is required",
  //     }));
  //     isValid = false;
  //   } else if (!/^\d{3}$/.test(formData.cvv.trim())) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       cvv: "Invalid CVV format (3 digits)",
  //     }));
  //     isValid = false;
  //   }

  //   if (isValid) {
  //     // Perform submission or further actions
  //     console.log("Form submitted:", formData);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (cardNumberElement && cardExpiryElement && cardCvcElement) {
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: cardName, // Include cardholder's name
      });

      if (error) {
        toast.error(error?.message);
        return;
      } else {
        dispatch(
          createCard(
            {
              tokenId: token?.id,
              userId: uid,
            },
            () => {
              dispatch(
                getCardDetails(uid, () => {
                  setIsEditing(false);
                })
              );
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    dispatch(
      getCardDetails(uid, () => {
        setIsEditing(false);
      })
    );
  }, []);

  const getIcon = (brand) => {
    switch (brand) {
      case "MasterCard":
        return MASTERCARD;
      case "American Express":
        return AMERICANEXPRESS;
      case "Discover":
        return DISCOVER;
      case "UnionPay":
        return UNIONPAY;
      case "Diners Club":
        return DINERSCLUB;
      case "JCB":
        return JCB;
      default:
        return VISA;
    }
  };

  return (
    <>
      <Form className="w-100 p-3 " onSubmit={handleSubmit}>
        {isGettingCardDetails ? (
          <div className="d-flex justify-content-center py-5 px-3">
            <Spinner></Spinner>
          </div>
        ) : (
          <>
            <h3>Payment Details</h3>

            {cardDetails && !isEditing ? (
              <FormGroup>
                {/* <Label className="checkout__label">
                  <h5>Payment Details</h5>
                </Label> */}
                <div className="w-100 border border-site-primary rounded-lg  d-flex flex-sm-row flex-column justify-content-between align-items-start align-items-sm-center p-2 p-md-3 mb-2">
                  <div className="d-flex  align-items-center">
                    {" "}
                    <div>
                      <img
                        src={getIcon(cardDetails?.brand)}
                        alt=""
                        height={50}
                        width={50}
                        className="stripe__card-icon"
                      ></img>
                    </div>
                    <h5 className="mb-0 ml-2">{cardDetails?.name}</h5>
                  </div>
                  <p className="mb-0">
                    <b>**** **** ****</b>
                    {cardDetails?.last4}
                  </p>
                </div>
                <p
                  className="float-right cursor-pointer text-primary"
                  onClick={() => {
                    setIsEditing(true);
                    setCardName(cardDetails?.name || "");
                  }}
                >
                  Change{" "}
                </p>
              </FormGroup>
            ) : (
              <>
                <FormGroup>
                  <Label for="address">Card Name</Label>

                  <Input
                    id="name"
                    name="cardName"
                    placeholder="Card Name"
                    type="text"
                    title="Card Name"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                    required
                  />
                </FormGroup>

                <FormGroup className="d-flex flex-column ">
                  <Label for="address">Card Number</Label>

                  <CardNumberElement
                    options={{ placeholder: "4242 4242 4242 4242" }}
                    className="form-control "
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="address">Expiry Date</Label>

                  <div>
                    <CardExpiryElement className="form-control " />
                  </div>
                </FormGroup>
                <FormGroup className="d-flex flex-column ">
                  <Label for="address">CVV</Label>

                  <CardCvcElement
                    options={{ placeholder: "CVV", maxLength: "4" }}
                    className="form-control form-control-solid "
                  />
                </FormGroup>
                <Button
                  className="w-100 bg-site-primary text-dark rounded-pill"
                  type="submit"
                  disabled={isAddingCard}
                >
                  {isAddingCard ? <Spinner size="sm" /> : "Save"}
                </Button>
              </>
            )}
          </>
        )}

        {/* <FormGroup>
          <Label for="cardNumber" hidden>
            Card Number
          </Label>
          <Input
            id="cardNumber"
            name="cardNumber"
            placeholder="Card Number"
            type="text"
            value={formData.cardNumber}
            onChange={handleChange}
            pattern="\d{16}"
            title="Invalid Card Number format (16 digits)"
            className={errors.cardNumber ? "is-invalid" : ""}
            required
          />
          {errors.cardNumber && (
            <div className="invalid-feedback">{errors.cardNumber}</div>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="expiryDate" hidden>
            Expiry Date
          </Label>
          <Input
            id="expiryDate"
            name="expiryDate"
            placeholder="MM/YYYY"
            type="text"
            value={formData.expiryDate}
            onChange={handleChange}
            pattern="^(0[1-9]|1[0-2])\/\d{4}$"
            title="Invalid Expiry Date format (MM/YYYY)"
            className={errors.expiryDate ? "is-invalid" : ""}
            required
          />
          {errors.expiryDate && (
            <div className="invalid-feedback">{errors.expiryDate}</div>
          )}
        </FormGroup>
        </Col>
        <Col md={6}>
        <FormGroup>
          <Label for="cvv">
            CVV
          </Label>
          <Input
            id="cvv"
            name="cvv"
            placeholder="CVV"
            type="text"
            value={formData.cvv}
            onChange={handleChange}
            pattern="^\d{3}$"
            title="Invalid CVV format (3 digits)"
            className={errors.cvv ? "is-invalid" : ""}
            required
          />
          {errors.cvv && <div className="invalid-feedback">{errors.cvv}</div>}
        </FormGroup> */}
        {/* <Button
          className="w-100 bg-site-primary text-dark rounded-pill"
          type="submit"
        >
          Save{" "}
        </Button> */}
      </Form>
    </>
  );
};

export default PaymentDetailsForm;
