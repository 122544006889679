import React from "react";
import { Col, Row } from "reactstrap";
import arrowUp from "../assets/img/arrowUp.svg";
import arrowDownDanger from "../assets/img/arrowDownDanger.svg";

const DashboardCard = ({ item }) => {
  return (
    <div className="dashboard__card p-3">
      <Row noGutters>
        <Col xs="8" className="d-flex flex-column">
          <p className="dashboard__card-title mb-1">{item?.title}</p>
          <p className="mb-0 dashboard__card-value text-dark">
            {item?.title === "Amount Spent" ? <>${item?.value}</> : item?.value}
          </p>
        </Col>
        <Col xs="4" className="d-flex justify-content-end">
          <div className="dashboard__card-icon p-2 bg-site-primary-dark d-flex justify-content-center align-items-center">
            <img src={item?.icon} />
          </div>
        </Col>
      </Row>
      {/* <Row noGutters className="mt-2">
        <Col className="d-flex align-items-center">
          <img src={arrowUp} className="mr-1" />
          <span className="mr-2 dashboard__card-percentage text-success">
            {item?.percentage}
          </span>{" "}
          <span className="dashboard__card-date">{item?.date}</span>
        </Col>
      </Row> */}
    </div>
  );
};

export default DashboardCard;
