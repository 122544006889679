import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

const WholeSeller = ({ wholeSeller, setWholeSeller, handleInputChange }) => {
  const handleRadioChange = (name, value) => {
    setWholeSeller((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Container className="text-responsive px-md-2 px-0">
      <Row>
        <Col xs={12}>
          <div className="d-flex ">
            <p className="mb-1">
              Are you able to meet the initial order minimum?{" "}
            </p>
            <FormGroup check className="ml-2">
              <Input
                name="initialOrder"
                type="radio"
                onChange={() => handleRadioChange("initialOrder", true)}
              />{" "}
              <Label check>
                <p className="mb-0">Yes</p>
              </Label>
            </FormGroup>
            <FormGroup check className="ml-2">
              <Input
                name="initialOrder"
                type="radio"
                onChange={() => handleRadioChange("initialOrder", false)}
              />{" "}
              <Label check>
                <p className="mb-0">No</p>
              </Label>
            </FormGroup>
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-flex ">
            <p>Do you sell online? </p>
            <FormGroup check className="ml-2">
              <Input
                name="sellOnline"
                type="radio"
                onChange={() => handleRadioChange("sellOnline", true)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">Yes</p>
              </Label>
            </FormGroup>
            <FormGroup check className="ml-2">
              <Input
                name="sellOnline"
                type="radio"
                onChange={() => handleRadioChange("sellOnline", false)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">No</p>
              </Label>
            </FormGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label className="auth__label">Url</Label>
            <Input
              type="text"
              placeholder="Url"
              className="text-dark"
              disabled={!wholeSeller?.sellOnline}
              name="url"
              //required
              value={wholeSeller?.url}
              onChange={(e) => handleInputChange(e, setWholeSeller)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="auth__label">Annual Online Sales</Label>
            <Input
              type="text"
              placeholder="Annual Online Sales"
              className="text-dark"
              disabled={!wholeSeller?.sellOnline}
              name="annualOnlineSales"
              //required
              value={wholeSeller?.annualOnlineSales}
              onChange={(e) => handleInputChange(e, setWholeSeller)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="d-flex">
            <p className="mb-1">
              Do you sell through classified ads sites such as Craigslist or
              Facebook Marketplace?
            </p>
            <FormGroup check className="ml-2">
              <Input
                name="classifiedAds"
                type="radio"
                onChange={() => handleRadioChange("classifiedAds", true)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">Yes</p>
              </Label>
            </FormGroup>
            <FormGroup check className="ml-2">
              <Input
                name="classifiedAds"
                type="radio"
                onChange={() => handleRadioChange("classifiedAds", false)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">No</p>
              </Label>
            </FormGroup>
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-flex">
            <p>
              Do you also sell through third party e-commerce platforms such as
              Amazon or eBay?
            </p>
            <FormGroup check className="ml-2">
              <Input
                id="thirdPartyYes"
                name="thirdParty"
                type="radio"
                onChange={() => handleRadioChange("thirdParty", true)}
              />
              <Label check htmlFor="thirdPartyYes">
                <p className="mb-0">Yes</p>
              </Label>
            </FormGroup>
            <FormGroup check className="ml-2">
              <Input
                id="thirdPartyNo"
                name="thirdParty"
                type="radio"
                onChange={() => handleRadioChange("thirdParty", false)}
              />
              <Label check htmlFor="thirdPartyNo">
                <p className="mb-0">No</p>
              </Label>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WholeSeller;
