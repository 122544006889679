import React from "react";
import { Col, Container, Row } from "reactstrap";
import beautyImg from "../assets/img/beautyimg.png"; // Replace with the correct path

export const Beauty = () => {
  return (
    <div className="bg-white py-5 beauty">
      <Container>
        <Row className="justify-content-between">
          <Col
            lg="7"
            md="8"
            className=" mt-md-0 mt-3 d-flex flex-column justify-content-center align-items-start h-100 order-2 order-md-1"
          >
            <p className="beauty__title text-dark">
              providing not just a look but a texture that defines{" "}
              <span className="text-site-primary-dark">beauty</span> with every
              strand.
            </p>
            <p className="mb-0 beauty__description text-dark">
              Redesign your elegance effortlessly with our product line,
              delivering unmatched style that complements your unique flair.
            </p>
          </Col>
          <Col
            md="4"
            // lg="3"
            className="h-100 d-flex justify-content-center order-1 order-md-2"
          >
            <img src={beautyImg} alt="Beauty Image" className="img-fluid " />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
