import {
  CART_ITEM,
  CART_LOADER,
  QUANTITY_CHANGE,
  SET_GET_CART_ITEMS_LOADER,
  UPDATE_TOTAL,
} from "../types";

const initialState = {
  carts: [],
  loader: false,
  totalAmount: null,
  isGettingCartItems: false,
};

export default function cartReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CART_ITEM:
      return {
        ...state,
        carts: payload,
      };

    case CART_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case QUANTITY_CHANGE:
      return {
        ...state,
        carts: payload,
      };
    case UPDATE_TOTAL:
      return {
        ...state,
        totalAmount: payload,
      };
    case SET_GET_CART_ITEMS_LOADER:
      return {
        ...state,
        isGettingCartItems: payload,
      };
    default:
      return { ...state };
  }
}
