import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyB4CyRg1h08mLZ1jdBtqE9_LRiiC1NqMLc",
  authDomain: "b2b--platform.firebaseapp.com",
  projectId: "b2b--platform",
  storageBucket: "b2b--platform.appspot.com",
  messagingSenderId: "555695213714",
  appId: "1:555695213714:web:68e0f610ed5d1800bbea4b",
  measurementId: "G-6Q3H91J2MF",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
