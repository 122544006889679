import firebase from "../../config/firebase";
import { SET_DASHBOARD_LOADING, SET_DASHBOARD_STATS } from "../types";

const statsCollection = firebase.firestore().collection("buyerDashboardStats");
export const setGetStatsLoader = (val) => async (dispatch) => {
  dispatch({ type: SET_DASHBOARD_LOADING, payload: val });
};

export const getDashboardStats = (id) => async (dispatch) => {
  dispatch(setGetStatsLoader(true));
  statsCollection.where("userId", "==", id).onSnapshot(async (query) => {
    if (query?.size > 0) {
      dispatch({
        type: SET_DASHBOARD_STATS,
        payload: {
          id: query.docs[0].id,
          ...query.docs[0].data(),
        },
      });
    } else {
      dispatch({
        type: SET_DASHBOARD_STATS,
        payload: {},
      });
    }
    dispatch(setGetStatsLoader(false));
  });
};
