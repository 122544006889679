import { toast } from "react-toastify";
import firebase from "../../config/firebase.js";
import {
  ALL_USERS,
  SET_CONTACT_US_LOADER,
  SET_CURRENT_PAGE,
  SET_GET_USER_DETAILS_LOADER,
  SET_LAST_VISIBLE,
  SET_PROGRESS_BAR,
  SET_SINGLE_USER_DETAILS,
  SET_SINGLE_USER_LOADING,
  UPDATE_USER_PIC,
} from "../types.js";
import { RepositoryFactory } from "../../repository/RepositoryFactory.js";
const usersCollection = firebase.firestore().collection("users");
const PAGE_SIZE = 5;

let user = RepositoryFactory.get("user");

export const setContactUsLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_CONTACT_US_LOADER, payload: val });
};
export const setGetUserDetailsLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_GET_USER_DETAILS_LOADER, payload: val });
};
export const setUserLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_SINGLE_USER_LOADING, payload: val });
};
export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
export const setLastVisible = (lastVisible) => ({
  type: SET_LAST_VISIBLE,
  payload: lastVisible,
});
export const getAllUser =
  (startAfter = null, state, city) =>
  async (dispatch) => {
    if (startAfter) {
      dispatch(setUserLoading(true));
    } else {
      dispatch(setGetUserDetailsLoading(true));
    }

    let query = usersCollection.orderBy("createdAt", "desc");

    if (state && city) {
      query = query.where("state", "==", state).where("city", "==", city);
    } else if (state) {
      query = query.where("state", "==", state);
    }

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    query = query.limit(PAGE_SIZE);
    const data = await query.get();

    let tempData = [];
    data.forEach((doc) => {
      tempData.push(doc.data());
    });

    // Store the reference to the last document
    if (tempData.length > 0) {
      dispatch(setLastVisible(data.docs[data.docs.length - 1]));
    }
    if (tempData?.length === 0 || tempData?.length < PAGE_SIZE) {
      dispatch(setLastVisible(null));
    }

    dispatch({
      type: ALL_USERS,
      payload: tempData,
    });
    dispatch(setGetUserDetailsLoading(false));
    dispatch(setUserLoading(false));
  };

export const getUserDetails = (id) => async (dispatch) => {
  dispatch(setGetUserDetailsLoading(true));
  usersCollection.doc(id).onSnapshot(async (query) => {
    if (query.data()) {
      dispatch({
        type: SET_SINGLE_USER_DETAILS,
        payload: { id: query.id, ...query.data() },
      });
    }
    dispatch(setGetUserDetailsLoading(false));
  });
};

// export const updateUserInfo = (id, payload) => async (dispatch) => {
//   dispatch(setUserLoading(true));
//   usersCollection
//     .doc(id)
//     .update({ ...payload, updatedAt: firebase.firestore.Timestamp.now() })
//     .then((res) => {
//       toast.success("Profile updated successfully!");
//       dispatch(setUserLoading(false));
//     })
//     .catch((err) => {
//       toast.error(err.message);
//       dispatch(setUserLoading(false));
//     });
// };
// import necessary dependencies

export const updateUserInfo =
  (id, payload, imageFile, preImg) => async (dispatch) => {
    dispatch(setUserLoading(true));
    if (preImg) {
      firebase.storage().refFromURL(preImg).delete();
    }
    // Check if an image file is provided
    if (imageFile) {
      const storageRef = firebase
        .storage()
        .ref("/temp" + `userImages/${id}/${imageFile.name}`);
      const uploadTask = storageRef.put(imageFile);
      // Track upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // Dispatch progress if needed
          dispatch({ type: SET_PROGRESS_BAR, payload: parseInt(progress) });
        },
        (error) => {
          console.error("Error uploading image:", error);
          dispatch(setUserLoading(false));
          toast.error("Error uploading image");
        },
        () => {
          // Upload completed successfully
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // Update user info in Firestore with the image URL
            usersCollection
              .doc(id)
              .update({ imageUrl: downloadURL })
              .then(() => {
                toast.success("Profile updated successfully!");
                dispatch({ type: SET_PROGRESS_BAR, payload: 0 });
                dispatch({ type: UPDATE_USER_PIC, payload: downloadURL });
                dispatch(setUserLoading(false));
              })
              .catch((err) => {
                toast.error(err.message);
                dispatch({ type: SET_PROGRESS_BAR, payload: 0 });
                dispatch(setUserLoading(false));
              });
          });
        }
      );
    } else {
      // If no image file is provided, update user info without uploading an image
      usersCollection
        .doc(id)
        .update({ ...payload, updatedAt: firebase.firestore.Timestamp.now() })
        .then(() => {
          toast.success("Profile updated successfully!");
          dispatch(setUserLoading(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setUserLoading(false));
        });
    }
  };

export const sendQuery =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setContactUsLoading(true));
    try {
      let { data } = await user.sendQuery(payload);
      dispatch(setContactUsLoading(false));
      onSuccess();
      toast.success("Query sent successfully!");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
      dispatch(setContactUsLoading(false));
    }
  };
