import React from "react";
import { Col, Container, Row } from "reactstrap";
import ContactusForm from "../components/ContactusForm";

const Contactus = () => {
  return (
    <>
      <div className="py-md-5  py-3">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <h1 className="text-md-center text-start page-title">
                Contact Us
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center mt-md-3 mt-0">
            <Col md="6">
              <ContactusForm />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contactus;
