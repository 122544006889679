import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  ToastHeader,
} from "reactstrap";
import location from "../../assets/img/location.png";
import logo from "../../assets/img/logo.png";

import ThessaLogo from "../../assets/img/Thessa_Logo.svg";
import FormSection from "../../components/SignUp/FormSection";
import { signUp } from "../../store/actions/authAction";
import {
  getCities,
  getStates,
} from "../../store/actions/statesAndCitiesAction";

const Signup = () => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRegex = /^\d{10}$/;
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.product);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [signupDetails, setSignUpDetails] = useState({
    name: "",
    state: "",
    city: "",
    address: "",
    telephone: "",
    wholeSellerLicense: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [newAccountInfo, setNewAccountInfo] = useState({
    businessName: { value: "", error: false, msg: "" },
    dba: { value: "", error: false, msg: "" },
    stateOfIncorporation: { value: "", error: false, msg: "" },
    federalTaxID: { value: "", error: false, msg: "" },

    storePhone: { value: "", error: false, msg: "" },
  });
  const [primaryContact, setPrimaryContact] = useState({
    street: { value: "", error: false, msg: "" },
    state: { value: "", error: false, msg: "" },
    city: { value: "", error: false, msg: "" },
    zipCode: { value: "", error: false, msg: "" },
    name: { value: "", error: false, msg: "" },
    telephone: { value: "", error: false, msg: "" },
    fax: { value: "", error: false, msg: "" },
    email: { value: "", error: false, msg: "" },
    wholeSellerLicense: { value: "", error: false, msg: "" },
    address: { value: "", error: false, msg: "" },
    password: { value: "", error: false, msg: "" },
    confirmPassword: { value: "", error: false, msg: "" },
  });

  const [typeOfBusiness, setTypeOfBusiness] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({
    businessYears: "",
    noOfEmployees: "",
    storeSize: "",
    annualGrossSales: "",
  });
  const [entryType, setEntryType] = useState([]);

  const [wholeSeller, setWholeSeller] = useState({
    initialOrder: false,
    sellOnline: false,
    url: "",
    annualOnlineSales: "",
    classifiedAds: false,
    thirdParty: false,
  });
  const [principal, setPrincipal] = useState({
    ownerDetails: [
      {
        name: { value: "", error: false, msg: "" },
        street: { value: "", error: false, msg: "" },
        city: { value: "", error: false, msg: "" },
        state: { value: "", error: false, msg: "" },
        zipCode: { value: "", error: false, msg: "" },
        phoneOrEmail: { value: "", error: false, msg: "" },
      },
      {
        name: { value: "", error: false, msg: "" },
        street: { value: "", error: false, msg: "" },
        city: { value: "", error: false, msg: "" },
        state: { value: "", error: false, msg: "" },
        zipCode: { value: "", error: false, msg: "" },
        phoneOrEmail: { value: "", error: false, msg: "" },
      },
      {
        name: { value: "", error: false, msg: "" },
        street: { value: "", error: false, msg: "" },
        city: { value: "", error: false, msg: "" },
        state: { value: "", error: false, msg: "" },
        zipCode: { value: "", error: false, msg: "" },
        phoneOrEmail: { value: "", error: false, msg: "" },
      },
    ],
    storeOrEntityName: "",
    storeOrEntityAddress: "",
  });

  const [tradeReferences, setTradeReferences] = useState([
    { businessName: "", arManager: "", phoneOrEmail: "" },
    { businessName: "", arManager: "", phoneOrEmail: "" },
    { businessName: "", arManager: "", phoneOrEmail: "" },
  ]);
  const [securedCreditors, setSecuredCreditors] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState({
    name: "",
    title: "",
    date: "",
    signature: "",
  });

  const handleInputChange = (e, setStateType) => {
    const { name, value } = e.target;

    setStateType((prev) => ({ ...prev, [name]: value }));
  };
  const handleValidatedInputChange = (e, setStateType) => {
    const { name, value } = e.target;
    setStateType((prevData) => ({
      ...prevData,
      [name]: {
        ...prevData[name],
        value: value,
        error: false,
        msg: "",
      },
    }));
  };

  const updateFormDataWithSimplifiedData = (stateName) => {
    const updatedData = {};
    for (const key in stateName) {
      updatedData[key] = stateName[key].value;
    }
    return updatedData;
  };

  const fieldNoNeedToCheck = ["dba", "federalTaxID"];
  const validateAndSetErrors = async (state, stateToSet) => {
    const updatedState = { ...state };

    Object.keys(updatedState).forEach((key) => {
      const value = updatedState[key].value;

      if (value.trim() === "" && !fieldNoNeedToCheck.includes(key)) {
        updatedState[key].error = true;
        updatedState[key].msg = "This field is required";
      } else if (key === "email" && !emailRegex.test(value)) {
        updatedState[key].error = true;
        updatedState[key].msg = "Email format not valid";
      } else if (key == "password") {
        if (
          primaryContact.password.value != primaryContact.confirmPassword.value
        ) {
          updatedState["password"].error = true;
          updatedState["confirmPassword"].error = true;
          updatedState["password"].msg =
            "Password and confirm password not match!";
          updatedState["confirmPassword"].msg =
            "Password and confirm password not match!";
        }
      } else if (
        (key == "storePhone" || key == "telephone") &&
        !phoneRegex.test(value)
      ) {
        updatedState[key].error = true;
        updatedState[key].msg = "Invalid Phone Number (e.g 1234567890)";
      }
    });

    stateToSet(updatedState);
  };

  const checkPrrincipalValidation = async () => {
    const updatedOwnerDetails = { ...principal.ownerDetails[0] };

    Object.keys(updatedOwnerDetails).forEach((key) => {
      const value = updatedOwnerDetails[key].value;

      if (value.trim() === "") {
        updatedOwnerDetails[key].error = true;
        updatedOwnerDetails[key].msg = "This field is required";
      } else if (key === "email" && !emailRegex.test(value)) {
        updatedOwnerDetails[key].error = true;
        updatedOwnerDetails[key].msg = "Email format not valid";
      } else {
        updatedOwnerDetails[key].error = false;
        updatedOwnerDetails[key].msg = "";
      }
    });

    setPrincipal((prevData) => ({
      ...prevData,
      ownerDetails: [updatedOwnerDetails, ...prevData.ownerDetails.slice(1)],
    }));
  };

  const isAnyError = (obj) => {
    for (const key in obj) {
      if (obj[key].error) {
        return true;
      }
    }
    return false;
  };
  function moveToSection(sectionId) {
    const targetElement = document.getElementById(sectionId);

    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateAndSetErrors(primaryContact, setPrimaryContact);
    await validateAndSetErrors(newAccountInfo, setNewAccountInfo);
    await checkPrrincipalValidation();

    if (applicantDetails.signature == "") {
      setApplicantDetails((prev) => ({ ...prev, signature: "error" }));
      toast.error("Invalid data or field empty");

      moveToSection("section-11");
      return;
    }
    if (applicantDetails.name == "") {
      setApplicantDetails((prev) => ({ ...prev, name: "error" }));
      toast.error("Invalid data or field empty");

      moveToSection("section-11");
      return;
    }

    const isNewAccountInfoError = isAnyError(newAccountInfo);
    const isPrimaryContactError = isAnyError(primaryContact);
    const isPrincipalError = isAnyError(principal.ownerDetails[0]);
    let updatedOwnerDetails = [];
    principal.ownerDetails.map((ele) => {
      updatedOwnerDetails.push(updateFormDataWithSimplifiedData(ele));
    });

    let payload = {
      ...updateFormDataWithSimplifiedData(primaryContact),
      newAccountInfo: updateFormDataWithSimplifiedData(newAccountInfo),
      entryType,
      typeOfBusiness,
      businessInfo,
      wholeSeller,
      principal: { ...principal, ownerDetails: updatedOwnerDetails },
      tradeReferences,
      securedCreditors,
      applicantDetails,
    };

    if (isNewAccountInfoError || isPrimaryContactError || isPrincipalError) {
      toast.error("Invalid data or field empty");
      moveToSection("section-1");
    } else {
      console.log("payload: ", payload);
      // dispatch(
      //   signUp(payload, () => {
      //     history.push("/login");
      //   })
      // );
    }
  };
  useEffect(() => {
    let payload = {
      country: "United States",
    };
    dispatch(
      getStates(payload, (states) => {
        setStates(states?.map((state) => state.name));
      })
    );
  }, []);
  useEffect(() => {
    if (signupDetails?.state !== "") {
      let payload = {
        country: "United States",
        state: signupDetails?.state,
      };
      dispatch(
        getCities(payload, (cities) => {
          setCities(cities);
        })
      );
    }
  }, [signupDetails?.state]);

  return (
    <div className="bg-site-primary">
      <Form onSubmit={handleSubmit}>
        <Container className="auth-page">
          <Row className="auth justify-content-center ">
            <Col>
              <div className="d-flex flex-column align-items-center w-100">
                <img src={ThessaLogo} className="auth__logo" alt="" />
                <div className="auth__card">
                  {/* New Account Section */}
                  <FormSection
                    states={states}
                    cities={cities}
                    setWholeSeller={setWholeSeller}
                    wholeSeller={wholeSeller}
                    setNewAccountInfo={setNewAccountInfo}
                    newAccountInfo={newAccountInfo}
                    handleInputChange={handleInputChange}
                    setEntryType={setEntryType}
                    entryType={entryType}
                    primaryContact={primaryContact}
                    setPrimaryContact={setPrimaryContact}
                    typeOfBusiness={typeOfBusiness}
                    setTypeOfBusiness={setTypeOfBusiness}
                    principal={principal}
                    setPrincipal={setPrincipal}
                    tradeReferences={tradeReferences}
                    setTradeReferences={setTradeReferences}
                    securedCreditors={securedCreditors}
                    setSecuredCreditors={setSecuredCreditors}
                    setApplicantDetails={setApplicantDetails}
                    applicantDetails={applicantDetails}
                    setBusinessInfo={setBusinessInfo}
                    businessInfo={businessInfo}
                    handleValidatedInputChange={handleValidatedInputChange}
                  />
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      disabled={loading}
                      className="w-50 auth__btn text-dark bg-site-primary px-2 mt-2 mt-md-4"
                    >
                      {loading ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                  </div>
                </div>

                <Label className="mt-4 text-dark auth__label">
                  Alredy have an account?{" "}
                  <Link
                    to="/login"
                    className="text-primary cursor-pointer auth__link"
                  >
                    Login{" "}
                  </Link>
                </Label>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Signup;
