import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const ShowAttachmentModal = ({ modalOpen, toggleModal, attachments }) => {
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="xl" centered>
      {/* Modal header */}
      <ModalHeader toggle={toggleModal}>Attachments</ModalHeader>

      {/* Modal body */}
      <ModalBody>
        <Container>
          <Row>
            {attachments?.map((ele, index) => {
              return (
                <Col md={4} className="mt-3 ">
                  <Card
                    key={`ShowAttachmentModal-${index}`}
                    className="product__card"
                  >
                    <img
                      alt="Sample"
                      className=" cursor-pointer mt-3"
                      src={ele?.url}
                      height={180}
                      style={{ objectFit: "contain" }}
                    />
                    <CardBody>
                      <CardTitle tag="h5">{ele?.name}</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
            <Col></Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ShowAttachmentModal;
