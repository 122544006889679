import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { Button, Col, Container, Row } from "reactstrap";
import ProductCard from "../components/ProductCard";
import { getAllCarts } from "../store/actions/cartAction";
import {
  getAllProducts,
  getMoreProducts,
} from "../store/actions/productAction";

const Product = () => {
  const dispatch = useDispatch();
  const { products, isGettingProducts, lastVisible, isGettingMoreProducts } =
    useSelector((state) => state.product);

  const { isGettingCartItems } = useSelector((state) => state.cart);

  const [limit, setLimit] = useState(8);

  const { uid } = useSelector((state) => state.authUser);

  useEffect(() => {
    dispatch(getAllProducts(limit));
    if (uid) {
      dispatch(getAllCarts(uid));
    }
  }, []);
  return (
    <div className="py-md-5  py-3">
      <Container>
        <p className="text-start page-title">Products</p>
        {isGettingProducts || isGettingCartItems ? (
          <ShimmerSimpleGallery
            card
            imageHeight={300}
            row={2}
            col={3}
            caption
          />
        ) : (
          // <Spinner> </Spinner>
          <Row className="mt-md-3 my-3">
            {products?.map((product) => {
              let { price, material, img, name, id, colors } = product;

              return (
                <Col key={id} xl={3} lg={4} md={6} sm={6} className="mt-3">
                  <ProductCard
                    id={id}
                    imageUrl={img[0]}
                    name={name}
                    price={price}
                    material={material}
                    colors={colors}
                  />
                </Col>
              );
            })}

            {lastVisible && (
              <Col xs="12" className="d-flex justify-content-center">
                <Button
                  className="mt-2 bg-site-primary-dark text-dark border-0 rounded-0 btn btn-secondary"
                  onClick={() => {
                    dispatch(getMoreProducts(limit, lastVisible));
                  }}
                  disabled={isGettingMoreProducts}
                >
                  {isGettingMoreProducts ? "Loading more..." : "Load More"}
                </Button>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Product;
