import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { Button, Container, Table } from "reactstrap";
import location from "../assets/img/locationDark.png";
import placeHolder from "../assets/img/placeholder.jpg";
import SellerSearch from "../components/SellerSearch";
import { getAllUser } from "../store/actions/userActions";
import { getAllProducts } from "../store/actions/productAction";
import amazonLogo from "../assets/img/amazonLogo.png";
import walmartLogo from "../assets/img/walmartLogo.png";

const WholeSeller = () => {
  const dispatch = useDispatch();
  const { allUsers, loading, isGettingUserDetails, lastVisible } = useSelector(
    (state) => state.user
  );

  const { products, isGettingProducts } = useSelector((state) => state.product);

  const loadMore = () => {
    dispatch(getAllUser(lastVisible));
  };
  useEffect(() => {
    dispatch(getAllUser(lastVisible));
  }, []);
  useEffect(() => {
    dispatch(getAllProducts(1));
  }, []);
  return (
    <>
      <div className="py-md-5 py-3 bg-site-primary-dark">
        <Container>
          <p className="m-0 text-dark page-title text-center mb-3 py-md-5 py-2">
            LOCATE RETAILERS
          </p>
        </Container>
      </div>
      <div className="py-md-5 py-3 ">
        <Container>
          <SellerSearch title="SEARCH RETAILERS" />
          {isGettingUserDetails || isGettingProducts ? (
            <ShimmerCategoryList
              title={false}
              items={3}
              categoryStyle="STYLE_THREE"
            />
          ) : (
            <>
              <h2 className="mt-4">Online Locations</h2>

              {products.length > 0 ? (
                <>
                  <Table className="custom-table mt-2" responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="pl-0">Online Stores</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products[0]?.amazonLink != "" && (
                        <tr>
                          <td className="text-dark align-middle">
                            <div className="d-flex align-items-center">
                              <img
                                src={amazonLogo}
                                alt=""
                                className="custom-table__avatar mr-2"
                              />
                              Amazon
                            </div>
                          </td>
                          <td className="text-dark align-middle pl-0">
                            <Button
                              onClick={() =>
                                window.open(products[0]?.amazonLink, "_blank")
                              }
                              className="bg-site-primry  custom-table__btn px-3  text-dark"
                            >
                              Visit Site
                            </Button>
                          </td>
                        </tr>
                      )}
                      {products[0]?.walmartLink != "" && (
                        <tr>
                          <td className="text-dark align-middle">
                            <div className="d-flex align-items-center">
                              <img
                                src={walmartLogo}
                                alt=""
                                className="custom-table__avatar mr-2"
                              />
                              Walmart
                            </div>
                          </td>
                          <td className="text-dark align-middle pl-0">
                            <Button
                              onClick={() =>
                                window.open(products[0]?.walmartLink, "_blank")
                              }
                              className="bg-site-primry  custom-table__btn px-3  text-dark"
                            >
                              Visit Site{" "}
                            </Button>
                          </td>{" "}
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className=" mt-5 d-flex justify-content-center align-items-center">
                  <h3>Not Found..!</h3>
                </div>
              )}
              <h2 className="mt-4">Physical Locations</h2>

              {allUsers.length > 0 ? (
                <>
                  <Table className="custom-table mt-2" responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUsers?.map((user, idx) => {
                        return (
                          <tr key={idx}>
                            <td className="text-dark align-middle">
                              <div className="d-flex align-items-center">
                                <img
                                  src={user?.imageUrl || placeHolder}
                                  alt=""
                                  className="custom-table__avatar mr-2"
                                />
                                {user?.name}
                              </div>
                            </td>
                            <td className="text-dark align-middle">
                              {user?.city} , {user?.state}
                            </td>
                            <td className="text-dark align-middle ">
                              <div className="d-flex text-dark align-items-center w-100 justify-content-end">
                                <img src={location} className="mr-2" alt="" />{" "}
                                {user?.address}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {lastVisible && (
                    <div className="text-center mt-3">
                      <Button
                        color="site-primary"
                        onClick={loadMore}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Load More"}
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <div className=" mt-5 d-flex justify-content-center align-items-center">
                  <h3>Not Found..!</h3>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default WholeSeller;
