import axios from "axios";
const baseDomain = "https://countriesnow.space/api/v0.1/countries";
const baseURL = `${baseDomain}`;

let axiosObj1;
axiosObj1 = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});
export default axiosObj1;
