import React from "react";

const PageBreadCrum = () => {
  return (
    <>
      <p className="product__title mb-1">BRAIDS</p>
      <p className="product__bread-crum">
        Products <span className="mx-2">/</span> Braids
      </p>
    </>
  );
};

export default PageBreadCrum;
