import React from "react";
import { Container } from "reactstrap";

const About = () => {
  return (
    <div className="py-md-5  py-3">
      <Container>
        <div className="d-flex justify-contant-center">
          <p className="text-start page-title">About Us</p>
        </div>

        <div>
          <section>
            <h2>Welcome to Thessa: Your Braiding Hair Destination</h2>
            <p>
              At Thessa, we believe that your hair is your canvas, and we're
              here to provide you with the finest hair products to express your
              unique style. Our journey began with a passion for beautiful,
              hassle-free hair, and it has been an exciting ride ever since.
            </p>
            <h2>Our Story</h2>
            <p>
              Thessa was born out of a desire to offer hair enthusiasts a
              superior choice when it comes to hair products. Founded by a
              dedicated entrepreneur in the hair industry, Thessa is more than
              just a brand; it's a commitment to excellence.
            </p>
            <h2>Our Mission and Values</h2>
            <p>
              Our mission is simple yet powerful: to empower individuals to
              embrace their natural beauty through high-quality products that's
              accessible to everyone. Quality, affordability, and sustainability
              are the cornerstones of our brand.
            </p>
            <h2>Unparalleled Quality</h2>
            <p>
              At Thessa, we take pride in our products. Crafted with care, our
              products are made from the finest materials, ensuring durability
              and a natural look. We believe that everyone deserves hair that
              not only looks amazing but also feels incredible.
            </p>
            <h2>Customer Satisfaction</h2>
            <p>
              Your satisfaction is our top priority. We're dedicated to
              providing exceptional customer service and a guarantee that you'll
              love our products. Your trust in us drives our commitment to
              excellence.
            </p>
            <h2>The Future of Thessa</h2>
            <p>
              We're always looking ahead, innovating, and creating new products
              to enhance your braiding experience. Stay tuned for exciting
              product launches and updates as we continue to grow and evolve.
            </p>
            <h2>Contact Us</h2>
            <p>
              Have questions, feedback, or just want to say hello? We'd love to
              hear from you. Reach out to us on the contact us page.
            </p>
            <p>
              Thank you for choosing Thessa as your braiding hair destination.
              We can't wait to be a part of your hair journey!
            </p>
            {/* <p>
              Experience the perfect blend of quality and style with Thessa
              Kanekalon Braiding Hair – your go-to choice for stunning,
              long-lasting braids. Made from premium synthetic fibers, Thessa is
              designed to mimic the texture of natural hair, giving you a
              seamless and authentic look. Whether you're crafting box braids,
              cornrows, or crochet styles, our lightweight hair won't weigh you
              down, ensuring comfort from day to night.{" "}
            </p>

            <p>
              Transform your look today with Thessa – because when it comes to
              braiding, only the best will do.{" "}
            </p>
            <div className="my-5">
              <u>
                <h2>Tiktok:</h2>
              </u>
              <h6>🌟✨ Transform your look with Thessa Braiding Hair! ✨🌟</h6>
              <p>
                🔥 Unleash your creativity with our premium synthetic braids,
                designed for the ultimate hair enthusiasts! 🔥
              </p>
              <h6>Why Thessa? Here's the scoop:</h6>

              <ul>
                <li>
                  <span role="img" aria-label="rainbow">
                    🌈
                  </span>{" "}
                  <strong>Diverse Colors:</strong> Stand out with our vibrant,
                  long-lasting hues!
                </li>
                <li>
                  <span role="img" aria-label="woman">
                    💆♀️
                  </span>{" "}
                  <strong>Natural Texture:</strong> Enjoy the look and feel of
                  real hair!
                </li>
                <li>
                  <span role="img" aria-label="no-woman">
                    🙅♀️
                  </span>{" "}
                  <strong>Lightweight Comfort:</strong> Say goodbye to heavy
                  hair – Thessa is here!
                </li>
                <li>
                  <span role="img" aria-label="wrench">
                    🔧
                  </span>{" "}
                  <strong>Easy Styling:</strong> User-friendly for all your
                  favorite braided looks!
                </li>
              </ul>
              <p>🛒 Available NOW on Amazon and Walmart Marketplace!</p>
              <p> Don't just braid it, Thessa it! 💖</p>
              <p className="mb-0">
                💥 Tap to shop and start your Thessa journey today!
                💥#ThessaBraiding
              </p>
              <p> #StyleRevolution #BraidWithConfidence</p>
            </div>

            <div className="my-5">
              <u>
                <h2>Instagram:</h2>
              </u>
              <h6>
                ✨ Unveil the secret to exquisite braids with Thessa Braiding
                Hair! ✨
              </h6>

              <h6>Discover the magic:</h6>

              <ul>
                <li>
                  <span role="img" aria-label="rainbow">
                    💁♀️
                  </span>{" "}
                  <strong>Flawless Texture: </strong>Luxurious feel, natural
                  look.
                </li>
                <li>
                  <span role="img" aria-label="woman">
                    🎨
                  </span>{" "}
                  <strong>Vivid Shades:</strong> Color that pops and lasts!
                </li>
                <li>
                  <span role="img" aria-label="no-woman">
                    🍃
                  </span>{" "}
                  <strong>Featherlight Touch:</strong> Comfort that lasts all
                  day.
                </li>
                <li>
                  <span role="img" aria-label="wrench">
                    ✂️
                  </span>{" "}
                  <strong>DIY Friendly:</strong> Easy to braid for all your
                  stylish needs.
                </li>
              </ul>
              <p>
                🛍️ Shop Thessa on Amazon and Walmart Marketplace – Your ultimate
                braiding companion awaits! Embrace the braid with Thessa. 💕
              </p>
              <p>
                {" "}
                🔗 Click the link in bio to buy now! 🔗 #ThessaBraids
                #BraidLikeABoss #HairGoals
              </p>
            </div>{" "}
            */}
          </section>
        </div>
      </Container>
    </div>
  );
};

export default About;
