import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { Button, Container } from "reactstrap";
import Img1 from "../../src/assets/img/img1.jpg";
import Img2 from "../../src/assets/img/img2.jpg";
import Img3 from "../../src/assets/img/img3.jpg";

const NewHeroSection = () => {
  const history = useHistory();

  const arr = [
    {
      img: Img1,
      title:
        "Strand by strand, unleash your radiance with our braided brilliance",
      description: "where beauty meets confidence!",
    },
    {
      img: Img2,
      title:
        "Reveal your inner glow, one strand at a time, with our woven elegance",
      description:
        "Where beauty finds its stride and confidence shines through!",
    },
    {
      img: Img3,
      title:
        "Strand by strand, unleash your radiance with our braided brilliance",
      description: "where beauty meets confidence!",
    },
  ];
  return (
    <Container fluid className="px-0">
      <Carousel
        interval={2000}
        nextLabel={""}
        prevLabel={""}
        indicators={false}
        fade
      >
        {arr.map(({ img, title, description }) => {
          return (
            <Carousel.Item>
              {" "}
              <img
                className="d-block vw-100 hero-section__carousel-img"
                src={img}
                alt="First slide"
                style={{ objectFit: "contain" }}
              />
              <Carousel.Caption className=" h-100">
                <div className=" d-flex flex-column justify-content-center h-100 hero-section__carousel-Caption ">
                  {" "}
                  <h2 className="hero-section__carousel-title  ">{title}</h2>
                  <p className="hero-section__carousel-description">
                    {description}
                  </p>
                  <Button
                    className="px-4 custom__btn text-dark"
                    onClick={() => history.push("/retailers")}
                  >
                    Shop Now
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default NewHeroSection;
