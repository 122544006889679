import Repository from "./Repository";
const checkout = "/order/create";

export default {
  getCardDetails(userId) {
    return Repository.get(`/payment/get-cards/${userId}`);
  },
  addCard(payload) {
    return Repository.post(`/payment/add-card`, payload);
  },
  updateCard(payload) {
    return Repository.post(`/payment//update-card`, payload);
  },
};
