import { SET_DASHBOARD_LOADING, SET_DASHBOARD_STATS } from "../types";

const initialState = {
  isLoadingStats: false,
  stats: {},
};

export default function dashboardReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_DASHBOARD_LOADING:
      return {
        ...state,
        isLoadingStats: payload,
      };
    case SET_DASHBOARD_STATS:
      return {
        ...state,
        stats: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
