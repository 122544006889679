import React from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import order from "../assets/img/order.png";
import orderReceived from "../assets/img/orderReceived.png";
import amountSpent from "../assets/img/amountSpent.png";
import DashboardCard from "../components/DashboardCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDashboardStats } from "../store/actions/dashboardAction";
const Dashboard = () => {
  const { isLoadingStats, stats } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);

  const items = [
    {
      title: "Total Orders",
      value: stats?.totalOrders || 0,
      date: "Since last month",
      icon: order,
      percentage: "3.48",
    },
    {
      title: "Orders to Receive",
      value: stats?.activeOrders || 0,
      date: "Since last month",
      icon: orderReceived,
      percentage: "3.48",
    },
    {
      title: "Amount Spent",
      value: stats?.totalAmount || 0,
      date: "Since last month",
      icon: amountSpent,
      percentage: "3.48",
    },
  ];

  useEffect(() => {
    dispatch(getDashboardStats(uid));
  }, []);
  return (
    <div className="py-md-5  py-3">
      <Container>
        <p className="text-start page-title">Dashboard</p>
        <Row className="mt-md-3 mt-1">
          {isLoadingStats ? (
            <div className="d-flex py-5 px-3 justify-content-center w-100">
              <Spinner />
            </div>
          ) : (
            items?.map((item, idx) => {
              return (
                <Col key={idx} md="4" className="mt-3">
                  <DashboardCard item={item} />
                </Col>
              );
            })
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
