import React from "react";
import { Col, Container, Row } from "reactstrap";
import texture from "../assets/img/texture.png";
import style from "../assets/img/style.png";
import color from "../assets/img/color.png";

const ProductDelivery = () => {
  const items = [
    {
      title: "Texture",
      description: "our products weave a rich tapestry of texture",
      icon: texture,
    },
    {
      title: "Style",
      description: "Elevate your style with our braided product line",
      icon: style,
    },
    {
      title: "Color",
      description:
        "offering a kaleidoscope of colors to suit every mood and moment",
      icon: color,
    },
  ];
  return (
    <div className="bg-white py-5 product-delivery ">
      <Container>
        <p className="product-delivery__title">Our Products Deliver</p>

        <Row className="mt-md-5 mt-2">
          {items?.map((item, idx) => {
            return (
              <Col
                md="4"
                className="d-flex flex-column align-items-center mt-md-0 mt-3 "
                key={idx}
              >
                <div className="product-delivery__icon p-2 d-flex justify-content-center align-items-center bg-site-primary">
                  <img src={item?.icon} />
                </div>
                <p className="product-delivery__heading mb-0 mt-3">
                  {item?.title}
                </p>
                <p className="product-delivery__description mb-0 mt-2 text-site-secondary">
                  {item?.description}
                </p>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default ProductDelivery;
