import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { ALL_ORDERS, ORDER_LOADER, SET_AUTH_LOADING } from "../types";

let orderCollection = firebase.firestore().collection("orders");

export const setOrderLoading = (val) => async (dispatch) => {
  dispatch({ type: ORDER_LOADER, payload: val });
};

export const getAllOrders = (uid) => async (dispatch) => {
  try {
    dispatch(setOrderLoading(true));
    await orderCollection
      .where("userId", "==", uid)
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const orders = [];

        docs.forEach((doc) => {
          orders.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        dispatch({
          type: ALL_ORDERS,
          payload: orders,
        });
        dispatch(setOrderLoading(false));
      });
  } catch (error) {
    dispatch(setOrderLoading(false));

    toast.error(error?.message);
  }
};
