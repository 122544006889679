import React, { useState } from "react";
import { Card, CardBody, Collapse } from "reactstrap";
import ApplicantSection from "./ApplicantSection";
import EnterTypeSection from "./EnterTypeSection";
import NewAccountInfo from "./NewAccountInfo";
import PrimaryContactSection from "./PrimaryContactSection";
import PrincipalOwnerSection from "./PrincipalOwnerSection";
import SecuredCreditorsSections from "./SecuredCreditorsSections";
import SupportingDocSection from "./SupportingDocSection";
import TradeReferencesSection from "./TradeReferencesSection";
import TypeOfBusiness from "./TypeOfBusiness";
import {
  FaAngleDown,
  FaAngleRight,
  FaBox,
  FaBuilding,
  FaEdit,
  FaFileAlt,
  FaListAlt,
  FaLock,
  FaUser,
  FaUserFriends,
  FaUserTie,
} from "react-icons/fa";
import WholeSeller from "./WholeSeller";
import BusinessInfoSection from "./BusinessInfoSection";

const Section = ({ id, icon, title, component }) => {
  const [isOpen, setIsOpen] = useState(id == "1");

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={id} id={`section-${id}`} className="mt-4">
      <div
        onClick={toggle}
        className="d-flex align-items-center  border p-3 rounded shadow-sm cursor-pointer mb-1"
      >
        {" "}
        <span className="fs--20 mx-2"> {icon && icon}</span>
        <h6 className="mb-0 fw--700">{title}</h6>
        <div className="ml-auto ">
          {isOpen ? <FaAngleDown /> : <FaAngleRight />}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>{component}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

function FormSection(props) {
  const sections = [
    {
      id: "1",
      title: "Primary Contact",
      component: <PrimaryContactSection {...props} />,
      icon: <FaUserFriends />,
    },
    {
      id: "2",
      title: "New Account Info",
      component: <NewAccountInfo {...props} />,
      icon: <FaUser />,
    },
    {
      id: "3",
      title: "Entry Type",
      component: <EnterTypeSection {...props} />,
      icon: <FaEdit />,
    },
    {
      id: "4",
      title: "Type of Business",
      component: <TypeOfBusiness {...props} />,
      icon: <FaBuilding />,
    },
    {
      id: "5",
      title: "Business Info",
      component: <BusinessInfoSection {...props} />,
      icon: <FaBuilding />,
    },
    {
      id: "6",
      title: "Whole Seller",
      component: <WholeSeller {...props} />,
      icon: <FaBox />,
    },
    {
      id: "7",
      title: "Supporting Documents",
      component: <SupportingDocSection {...props} />,
      icon: <FaFileAlt />,
    },
    {
      id: "8",
      title: "Principal",
      component: <PrincipalOwnerSection {...props} />,
      icon: <FaUserTie />,
    },
    {
      id: "9",
      title: "Trade References",
      component: <TradeReferencesSection {...props} />,
      icon: <FaListAlt />,
    },
    {
      id: "10",
      title: "Secured Creditors",
      component: <SecuredCreditorsSections {...props} />,
      icon: <FaLock />,
    },
    {
      id: "11",
      title: "Term and Conditions",
      component: <ApplicantSection {...props} />,
      icon: <FaLock />,
    },
  ];
  return (
    <div>
      {sections.map((section) => (
        <Section key={section.id} {...section} />
      ))}
    </div>
  );
}

export default FormSection;
