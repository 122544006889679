import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import logo from "../../assets/img/logo.png";
import viewIcon from "../../assets/img/icon-view.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword, login } from "../../store/actions/authAction";
import { FaArrowLeft } from "react-icons/fa";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotten, setIsForgotten] = useState(false);

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleDetailsChange = (e) => {
    setLoginDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   isForgotten?dispatch(forgetPassword(loginDetails?.email,()=>{setIsForgotten(false)})):  dispatch(login(loginDetails));
  };
  return (
    <div className="bg-site-primary">
      <Form onSubmit={handleSubmit}>
        <Container className="auth-page">
          <Row className="auth justify-content-center">
            <Col md="6" lg="5">
              <div className="d-flex flex-column align-items-center w-100">
                <img src={logo} className="auth__logo" />
                <div className="auth__card">
                 { isForgotten &&<div className="cursor-pointer mb-2" onClick={()=>setIsForgotten(false)}><FaArrowLeft /></div>}
                  <FormGroup>
                    <Label className="auth__label">Email</Label>
                    <Input
                      type="email"
                      placeholder="youremail@gmail.com"
                      className="text-dark"
                      required
                      name="email"
                      value={loginDetails?.email}
                      onChange={handleDetailsChange}
                    />
                  </FormGroup>
                  {!isForgotten &&  <FormGroup className="mt-2 mt-md-4">
                    <div className="d-flex justify-content-between">
                      <Label className="auth__label">Password</Label>
                      {/* <Link className=" text-primary cursor-pointer auth__label auth__link">
                        Forgot password?{" "}
                      </Link> */}
                      <h5  className=" text-primary cursor-pointer auth__label auth__link" onClick={()=>setIsForgotten(true)}>Forgot password?</h5>
                    </div>
                   <div className="w-100 position-relative">
                      <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter you password"
                        className="text-dark w-100 auth__icon-field"
                        required
                        name="password"
                        value={loginDetails?.password}
                        onChange={handleDetailsChange}
                      />
                      {/* <img className="auth__icon" src={viewIcon} /> */}
                      {!showPassword ? (
                        <i
                          className="fa fa-eye auth__icon"
                          onClick={showPasswordToggle}
                        />
                      ) : (
                        <i
                          className="fa fa-eye-slash auth__icon"
                          onClick={showPasswordToggle}
                        />
                      )}
                    </div>
                  </FormGroup>
                }
                  <Button
                    disabled={loading}
                    className="w-100 auth__btn text-dark bg-site-primary px-2 mt-2 mt-md-4"
                  >
                    {loading ? <Spinner size="sm" /> : isForgotten?"Send":"Login"}
                  </Button>
                </div>

                <Label className="mt-4 text-dark auth__label">
                  Don’t have an account yet?{" "}
                  <Link
                    to="/signup"
                    className="text-primary cursor-pointer auth__link"
                  >
                    Sign up
                  </Link>
                </Label>
              </div>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

export default Login;
