import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Spinner,
} from "reactstrap";
import { addToCart, deleteCartItem } from "../store/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";

const ProductCard = ({ id, imageUrl, name, price, material, colors }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { loader, carts } = useSelector((state) => state.cart);
  const [addToCardID, setAddToCard] = useState("");

  const isAlreadyInCart = (itemId) => {
    if (carts && carts.length > 0 && carts[0].items) {
      let found = carts[0].items.find((item) => item.productId === itemId);
      return found ? true : false;
    } else {
      return false;
    }
  };

  const handleAddToCart = (event) => {
    event.stopPropagation();
    setAddToCard(id);
    let payload = {
      productId: id,
      image: imageUrl,
      quantity: 1,
      name,
      price,
      material,
      color: colors[0],
    };

    dispatch(
      addToCart(payload, uid, () => {
        setAddToCard("");
      })
    );
  };
  return (
    <Card className="h-100 product__card text-center shadow-sm border">
      <img
        alt="Sample"
        className=" cursor-pointer mt-3"
        src={imageUrl}
        height={180}
        style={{ objectFit: "contain" }}
        onClick={() => history.push(`/product-details/${id}`)}
      />
      <CardBody>
        <CardTitle tag="h6" className="mt-2">
          {name} - {material}
        </CardTitle>

        {uid && (
          <>
            <CardText className="fs--14 "> USD$ {price}</CardText>
            {isAlreadyInCart(id) ? (
              <Button
                color="site-primary"
                outline
                disabled={loader && id == addToCardID}
                className=" w-100 bg-dark text-white "
                onClick={(event) => {
                  event.stopPropagation();
                  dispatch(
                    deleteCartItem(uid, id, () => {
                      setAddToCard("");
                    })
                  );
                }}
              >
                {loader && id == addToCardID ? (
                  <Spinner size="sm"> </Spinner>
                ) : (
                  "Remove from Cart"
                )}
              </Button>
            ) : (
              <Button
                color="site-primary"
                outline
                disabled={loader && id == addToCardID}
                className=" w-100 text-dark "
                onClick={(event) => {
                  handleAddToCart(event);
                }}
              >
                {loader && id == addToCardID ? (
                  <Spinner size="sm"> </Spinner>
                ) : (
                  "Add to Cart"
                )}
              </Button>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ProductCard;
