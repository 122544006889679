import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";

const SecuredCreditorsSections = ({
  setSecuredCreditors,
  securedCreditors,
}) => {
  return (
    <Container className="px-md-2 px-0 text-responsive ">
      <h4 className="text-center">Secured Creditors</h4>
      <Row>
        <Col xs={12}>
          <div className="d-flex ">
            <p className="mb-0 fs--14">
              Do any of the Applicant’s creditors (such as banks and suppliers)
              have a lien on Applicant’s inventory, accounts receivable, and/or
              personal property?
            </p>
            <FormGroup check className="ml-2">
              <Input
                name="radio1"
                type="radio"
                onClick={() => setSecuredCreditors(true)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">Yes</p>
              </Label>
            </FormGroup>
            <FormGroup check className="ml-2">
              <Input
                name="radio1"
                type="radio"
                onClick={() => setSecuredCreditors(false)}
              />{" "}
              <Label check>
                {" "}
                <p className="mb-0">No</p>
              </Label>
            </FormGroup>
          </div>
        </Col>
        <Col xs={12}>
          <p className="fs--12">
            (If Yes, please provide a list of such creditors in a separate
            sheet.)
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SecuredCreditorsSections;
