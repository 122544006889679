import Checkout from "../views/Checkout";
import Dashboard from "../views/Dashboard";
import Home from "../views/Home";
import OrderList from "../views/OrderList";
import Product from "../views/Product";
import ProductDetails from "../views/ProductDetails";
import WholeSeller from "../views/WholeSeller";
import Login from "../views/auth/Login";
import Signup from "../views/auth/Signup";
import About from "../views/About";
import Contactus from "../views/Contactus";
import MyProfile from "../views/MyProfile";

let routes = [
  {
    path: "/login",
    component: Login,
    layout: "auth",
  },
  {
    path: "/signup",
    component: Signup,
    layout: "auth",
  },
  {
    path: "/",
    component: Home,
    layout: "public",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    layout: "main",
  },
  {
    path: "/order-list",
    component: OrderList,
    layout: "main",
  },
  {
    path: "/product",
    component: Product,
    layout: "public",
  },
  {
    path: "/retailers",
    component: WholeSeller,
    layout: "public",
  },
  {
    path: "/product-details/:id",
    component: ProductDetails,
    layout: "public",
  },
  {
    path: "/checkout",
    component: Checkout,
    layout: "public",
  },
  {
    path: "/about-us",
    component: About,
    layout: "public",
  },
  {
    path: "/contact-us",
    component: Contactus,
    layout: "public",
  },
  {
    path: "/my-profile",
    component: MyProfile,
    layout: "main",
  },
];
export default routes;
