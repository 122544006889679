import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import PageBreadCrum from "../components/PageBreadCrum";
import hairbulk from "../assets/img/hairbulk.png";
import hairbulk2 from "../assets/img/hairbulk2.png";
import color1 from "../assets/img/color1.png";
import color2 from "../assets/img/color2.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllColors, getSingleProduct } from "../store/actions/productAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  addToCart,
  deleteCartItem,
  getAllCarts,
} from "../store/actions/cartAction";

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const {
    loader: cartloader,
    isGettingCartItems,
    carts,
  } = useSelector((state) => state.cart);
  const { singleProduct, loader, colors, colorLoader } = useSelector(
    (state) => state.product
  );

  const [selectedColor, setSelectedColor] = useState("");
  const isAlreadyInCart = (itemId) => {
    if (carts && carts.length > 0 && carts[0].items) {
      let found = carts[0].items.find((item) => item.productId === itemId);

      return found ? true : false;
    } else {
      return false;
    }
  };

  const handleAddToCart = (event) => {
    const { id, img, name, price, material } = singleProduct;

    let payload = {
      productId: id,
      image: img[0],
      quantity: 1,
      name,
      price,
      material,
      color: selectedColor,
    };

    dispatch(addToCart(payload, uid));
  };
  useEffect(() => {
    dispatch(getSingleProduct(id));
  }, [id]);

  useEffect(() => {
    if (uid) {
      dispatch(getAllCarts(uid));
    }
    dispatch(getAllColors());
  }, []);
  useEffect(() => {
    if (singleProduct && carts && carts.length > 0 && carts[0].items) {
      let found = carts[0]?.items.find(
        (item) => item.productId === singleProduct?.id
      );

      if (found) {
        setSelectedColor(found.color);
      }
    }
  }, [singleProduct, carts]);

  return (
    <>
      {loader || isGettingCartItems || colorLoader ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Spinner> </Spinner>
        </div>
      ) : (
        <div className="py-3 py-md-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-3">
                <PageBreadCrum />
              </Col>

              <Col md="6">
                <div className="w-100 d-flex align-items-center justify-content-center p-3 product-details__image-large">
                  {Object.keys(singleProduct).length != 0 && (
                    <img src={singleProduct?.img[0]} alt="" />
                  )}
                </div>

                {Object.keys(singleProduct).length != 0 &&
                  singleProduct.img.length > 1 && (
                    <div className="d-flex flex-wrap">
                      {singleProduct?.img.slice(1).map((subImg, idx) => {
                        return (
                          <div
                            key={idx}
                            className="mr-3 mt-3 w-100 d-flex align-items-center justify-content-center p-1 product-details__image-small position-relative"
                          >
                            <img src={subImg} alt="" />
                          </div>
                        );
                      })}
                    </div>
                  )}
              </Col>

              <Col md="6" className="mt-md-0 mt-3">
                <p className="text-dark product-details__heading">
                  {singleProduct?.name}
                </p>
                <p className="text-dark product-details__description">
                  {singleProduct?.description}
                </p>

                <hr className="my-4" />

                <Row noGutters>
                  <Col xs="4" className="mt-2">
                    <p className="m-0 product-details__label">TEXTURE</p>
                  </Col>
                  <Col xs="8" className="mt-2 ">
                    <ul className="m-0 product-details__value text-dark product-details__list">
                      {singleProduct?.texture &&
                        singleProduct?.texture
                          .split("/")
                          .map((item, index) => (
                            <li key={index}>{item.trim()}</li>
                          ))}
                    </ul>
                    {/* <p className="m-0 product-details__value text-dark">
                      {" "}
                      {singleProduct?.texture}
                    </p> */}
                  </Col>
                  {/* <Col xs="4" className="mt-3">
                    <p className="m-0 product-details__label">STYLE</p>
                  </Col>
                  <Col xs="8" className="mt-3">
                    <p className="m-0 product-details__value text-dark">
                      {singleProduct?.style}
                    </p>
                  </Col> */}
                  <Col xs="4" className="mt-3">
                    <p className="m-0 product-details__label">COLOR SHOWN</p>
                  </Col>
                  <Col xs="8" className="mt-3">
                    <p className="m-0 product-details__value text-dark">
                      NATURAL BLACK{" "}
                    </p>
                  </Col>
                  <Col xs="4" className="mt-3">
                    <p className="m-0 product-details__label">
                      AVAILABLE COLORS
                    </p>
                  </Col>
                  <Col xs="8" className="mt-3">
                    <p className="m-0 product-details__value text-dark">
                      {/* 27,30,425, NATURAL, NATURAL BLACK, T1B/27, T1B/30, T1B/425{" "} */}
                      {singleProduct?.availableColors}
                    </p>
                  </Col>
                  <Col xs="4" className="mt-3">
                    <p className="m-0 product-details__label">COLORS</p>
                  </Col>
                  <Col xs="8" className=" d-flex flex-wrap gap-1 mt-3">
                    {singleProduct?.colors?.map((id, idx) => {
                      let currentColor = colors.find(
                        (color) => color?.id === id
                      );
                      return (
                        <div key={idx} className="color-container">
                          <img
                            src={currentColor?.url}
                            alt=""
                            className={` mr-2 ${
                              selectedColor === currentColor?.id
                                ? "product-details__selected-card"
                                : "product-details__color"
                            }`}
                            onClick={() => setSelectedColor(currentColor?.id)}
                          />
                          <span className="color-name">
                            {currentColor?.name}
                          </span>
                        </div>
                        // <>
                        //   <img
                        //     src={currentColor?.url}
                        //     key={idx}
                        //     alt=""
                        //     className={` mt-3 mr-2 ${
                        //       selectedColor === currentColor?.id
                        //         ? "product-details__selected-card"
                        //         : "product-details__color"
                        //     }`}
                        //     onClick={() => setSelectedColor(currentColor?.id)}
                        //   />
                        // </>
                      );
                    })}
                  </Col>
                  <Col xs="12" className="mt-4 product">
                    <a
                      href={singleProduct?.amazonLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        className="product-details__btn w-100 d-flex justify-content-center align-items-center px-3 w-100 fw--700 text-dark rounded-0 border-0 bg-site-primary-dark"
                        // onClick={() =>
                        //   openLinkInNewTab(singleProduct?.amazonLink)
                        // }
                      >
                        View Amazon Listing
                      </Button>
                    </a>
                  </Col>
                  <Col xs="12" className="mt-4">
                    {/* <a
                      href={singleProduct?.walmartLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                    <Button
                      onClick={() => {
                        window.open(singleProduct?.walmartLink, "_blank");
                      }}
                      className="product-details__btn w-100 d-flex justify-content-center align-items-center px-3 w-100 fw--700 text-dark rounded-0 border-0 bg-site-primary-dark"
                    >
                      View Walmart Listing
                    </Button>
                    {/* </a> */}
                  </Col>
                  {uid && (
                    <Col xs="12" className="mt-4">
                      {isAlreadyInCart(id) ? (
                        <Button
                          color="site-primary"
                          outline
                          disabled={cartloader}
                          className=" w-100 bg-dark text-white "
                          onClick={() => {
                            dispatch(deleteCartItem(uid, id, () => {}));
                          }}
                        >
                          {cartloader ? (
                            <Spinner size="sm"> </Spinner>
                          ) : (
                            "Remove from Cart"
                          )}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAddToCart}
                          disabled={cartloader}
                          className="product-details__btn w-100 d-flex justify-content-center align-items-center px-3 w-100 fw--700 text-dark rounded-0 border-0 bg-site-primary-dark"
                        >
                          {/* FIND A STORE */}
                          {cartloader ? (
                            <Spinner sm={"sm"}> </Spinner>
                          ) : (
                            "Add to cart"
                          )}
                        </Button>
                      )}
                    </Col>
                  )}
                  <Col xs="12">
                    <hr className="mt-5" />
                  </Col>{" "}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
