import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import CustomCheckBox from "../common/CustomCheckBox";

const BusinessInfoSection = ({
  handleInputChange,
  setBusinessInfo,
  businessInfo,
}) => {
  return (
    <Container>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Years in Business</Label>
            <Input
              type="number"
              placeholder="Years"
              className="text-dark"
              name="businessYears"
              maxLength={9}
              //required
              value={businessInfo?.businessYears}
              onChange={(e) => handleInputChange(e, setBusinessInfo)}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Number of Employees</Label>
            <Input
              type="number"
              placeholder="Number of Employees"
              className="text-dark"
              name="noOfEmployees"
              maxLength={9}
              //required
              value={businessInfo?.noOfEmployees}
              onChange={(e) => handleInputChange(e, setBusinessInfo)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Store Size</Label>
            <Input
              type="number"
              placeholder="store size in sq. ft."
              className="text-dark"
              name="storeSize"
              maxLength={9}
              //required
              value={businessInfo?.storeSize}
              onChange={(e) => handleInputChange(e, setBusinessInfo)}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Annual Gross Sales</Label>
            <Input
              type="number"
              placeholder="Annual Gross Sales"
              className="text-dark"
              maxLength={15}
              name="annualGrossSales"
              style={{ appearance: "textfield" }}
              //required
              value={businessInfo?.annualGrossSales}
              onChange={(e) => handleInputChange(e, setBusinessInfo)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default BusinessInfoSection;
