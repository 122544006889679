import { ALL_ORDERS, ORDER_LOADER } from "../types";

const initialState = {
  orders: null,
  isGettingOrders: false,
};

export default function orderReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ALL_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case ORDER_LOADER:
      return {
        ...state,
        isGettingOrders: payload,
      };

    default:
      return { ...state };
  }
}
