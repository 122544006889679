import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Container } from "reactstrap";

const ShopNow = () => {
  const history = useHistory();
  return (
    <div className="bg-site-primary  py-5 show-now">
      <Container>
        <p className="show-now__title ">
          shop our stunning product line now and own the spotlight!
        </p>
        <div className="d-flex justify-content-center mt-3 mt-md-5">
          <Button
            className="custom__btn text-dark px-4 "
            onClick={() => history.push("/retailers")}
          >
            View Our Partners
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ShopNow;
