import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { sendQuery } from "../store/actions/userActions";

const ContactusForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    query: "",
  });

  const dispatch = useDispatch();

  const { isSendingQuery } = useSelector((state) => state.user);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      sendQuery(formData, () => {
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          query: "",
        });
      })
    );
  };

  // const [errors, setErrors] = useState({
  //   name: "",
  //   email: "",
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: "",
    // }));
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Validation
  //   let isValid = true;

  //   if (!formData.name.trim()) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       name: "Name is required",
  //     }));
  //     isValid = false;
  //   }

  //   if (!/^[A-Za-z\s]+$/.test(formData.name.trim())) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       name: "Name can only contain letters and spaces",
  //     }));
  //     isValid = false;
  //   }

  //   if (!formData.email.trim()) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       email: "Email is required",
  //     }));
  //     isValid = false;
  //   }

  //   if (!/\S+@\S+\.\S+/.test(formData.email.trim())) {
  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       email: "Invalid email format",
  //     }));
  //     isValid = false;
  //   }

  //   if (isValid) {
  //     // Perform submission or further actions
  //     console.log("Form submitted:", formData);
  //   }
  // };

  return (
    <Form className="w-md-50  mx-auto" onSubmit={handleSubmit}>
      {/* <div className="d-flex justify-content-center p-3"></div> */}
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              id="fname"
              name="firstName"
              placeholder="First Name"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              pattern="[A-Za-z\s]+"
              title="Name can only contain letters and spaces"
              required
            />
            {/* {errors.name && (
              <div className="invalid-feedback">
                {errors.firstName}
                <div className="instructions">
                  Please enter a valid first name.
                </div>
              </div>
            )} */}
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              pattern="[A-Za-z\s]+"
              title="Name can only contain letters and spaces"
              required
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          id="email"
          name="email"
          placeholder="Email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          pattern="\S+@\S+\.\S+"
          title="Invalid email format"
          required
        />
        {/* {errors.email && (
          <div className="invalid-feedback">
            {errors.email}
            <div className="instructions">Please enter a valid email.</div>
          </div>
        )} */}
      </FormGroup>

      <FormGroup>
        <Label for="textarea">Message</Label>
        <Input
          style={{ resize: "none" }}
          id="textarea"
          name="query"
          placeholder="Message..."
          type="textarea"
          value={formData.query}
          onChange={handleChange}
          rows={5}
          required
        />
      </FormGroup>

      <Button
        disabled={isSendingQuery}
        className="w-100 bg-site-primary text-dark mb-3"
        type="submit"
      >
        {isSendingQuery ? <Spinner /> : "Submit"}
      </Button>
    </Form>
  );
};

export default ContactusForm;
