import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { getCities } from "../../store/actions/statesAndCitiesAction";

const PrimaryContactSection = ({
  states,
  primaryContact,
  setPrimaryContact,
  handleValidatedInputChange,
}) => {
  const dispatch = useDispatch();
  const { loader } = useSelector((state) => state.product);
  const [citiesState, setCitiesState] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const showConfirmPasswordToggle = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };
  useEffect(() => {
    if (primaryContact?.state.value !== "") {
      let payload = {
        country: "United States",
        state: primaryContact?.state.value,
      };
      dispatch(
        getCities(payload, (cities) => {
          setCitiesState(cities);
        })
      );
    }
  }, [primaryContact?.state.value]);
  return (
    <Container>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Name*</Label>
            <Input
              type="text"
              placeholder="Enter Name"
              className={`text-dark ${
                primaryContact?.name.error ? "border-danger" : ""
              }`}
              name="name"
              //required
              value={primaryContact?.name.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.name.error && (
              <small className="text-danger">{primaryContact?.name.msg}</small>
            )}
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Phone*</Label>
            <Input
              type="number"
              placeholder="Phone"
              className={`text-dark ${
                primaryContact?.telephone.error ? "border-danger" : ""
              }`}
              name="telephone"
              //required
              value={primaryContact?.telephone.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.telephone.error && (
              <small className="text-danger">
                {primaryContact?.telephone.msg}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Email*</Label>
            <Input
              type="text"
              placeholder="Email"
              className={`text-dark ${
                primaryContact?.email.error ? "border-danger" : ""
              }`}
              name="email"
              //required
              value={primaryContact?.email.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.email.error && (
              <small className="text-danger">{primaryContact?.email.msg}</small>
            )}
          </FormGroup>
        </Col>{" "}
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Fax*</Label>
            <Input
              type="text"
              placeholder="Fax"
              className={`text-dark ${
                primaryContact?.fax.error ? "border-danger" : ""
              }`}
              name="fax"
              //required
              value={primaryContact?.fax.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.fax.error && (
              <small className="text-danger">{primaryContact?.fax.msg}</small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label className="auth__label">Wholesaler License</Label>

            <Input
              type="text"
              placeholder="Enter your wholesaler license"
              // className="text-dark w-100 "
              className={`text-dark ${
                primaryContact?.wholeSellerLicense.error ? "border-danger" : ""
              }`}
              name="wholeSellerLicense"
              //required
              value={primaryContact?.wholeSellerLicense.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.wholeSellerLicense.error && (
              <small className="text-danger">
                {primaryContact?.wholeSellerLicense.msg}
              </small>
            )}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label className="auth__label">Address</Label>

            <Input
              type="text"
              placeholder="Enter your address"
              // className="text-dark w-100 "
              className={`text-dark ${
                primaryContact?.address.error ? "border-danger" : ""
              }`}
              name="address"
              //required
              value={primaryContact?.address.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.address.error && (
              <small className="text-danger">
                {primaryContact?.address.msg}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3} sm={6} xs={12}>
          {" "}
          <FormGroup>
            <Label className="auth__label">Street</Label>
            <Input
              type="text"
              placeholder="Enter a location"
              // className="text-dark"
              className={`text-dark ${
                primaryContact?.street.error ? "border-danger" : ""
              }`}
              name="street"
              //required
              value={primaryContact?.street.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.street.error && (
              <small className="text-danger">
                {primaryContact?.street.msg}
              </small>
            )}
          </FormGroup>
        </Col>

        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">State</Label>
            {/* <Input
              type="text"
              placeholder="Enter your state"
              className="text-dark"
              name="state"
              //required
              value={newAccountInfo?.state}
              onChange={(e) => handleValidatedInputChange(e, setNewAccountInfo)}
            /> */}
            <Input
              //required
              type="select"
              name="state"
              // className="form-control-alternative text-site-grey"
              className={`form-control-alternative text-site-grey ${
                primaryContact?.state.error ? "border-danger" : ""
              }`}
              value={primaryContact?.state.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            >
              <option value="">State</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Input>
            {primaryContact?.state.error && (
              <small className="text-danger">{primaryContact?.state.msg}</small>
            )}
          </FormGroup>
        </Col>
        {primaryContact?.state && (
          <Col md={3} sm={6} xs={12}>
            <FormGroup>
              <Label className="auth__label">City</Label>

              <Input
                //required
                type="select"
                name="city"
                disabled={loader}
                // className="form-control-alternative text-site-grey"
                className={`form-control-alternative text-site-grey ${
                  primaryContact?.city.error ? "border-danger" : ""
                }`}
                value={primaryContact?.city.value}
                onChange={(e) =>
                  handleValidatedInputChange(e, setPrimaryContact)
                }
              >
                <option value="">{loader ? "loading..." : "City"}</option>
                {citiesState.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Input>
              {primaryContact?.city.error && (
                <small className="text-danger">
                  {primaryContact?.city.msg}
                </small>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md={3} sm={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">ZipCode</Label>
            <Input
              type="text"
              placeholder="zipcode"
              // className="text-dark"
              className={`text-dark ${
                primaryContact?.zipCode.error ? "border-danger" : ""
              }`}
              name="zipCode"
              //required
              value={primaryContact?.zipCode.value}
              onChange={(e) => handleValidatedInputChange(e, setPrimaryContact)}
            />
            {primaryContact?.zipCode.error && (
              <small className="text-danger">
                {primaryContact?.zipCode.msg}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          <FormGroup>
            <Label className="auth__label">Password*</Label>

            <div className="w-100 position-relative">
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className={`text-dark w-100 auth__icon-field ${
                  primaryContact?.password.error ? "border-danger" : ""
                }`}
                name="password"
                value={primaryContact?.password.value}
                onChange={(e) =>
                  handleValidatedInputChange(e, setPrimaryContact)
                }
                //required
              />
              {/* <img className="auth__icon" src={viewIcon} /> */}
              {!showPassword ? (
                <i
                  className="fa fa-eye auth__icon"
                  onClick={showPasswordToggle}
                />
              ) : (
                <i
                  className="fa fa-eye-slash auth__icon"
                  onClick={showPasswordToggle}
                />
              )}
            </div>
            {primaryContact?.password.error && (
              <small className="text-danger">
                {primaryContact?.password.msg}
              </small>
            )}
          </FormGroup>
        </Col>
        <Col>
          {" "}
          <FormGroup>
            <Label className="auth__label">Confirm password*</Label>
            <div className="w-100 position-relative">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm password"
                // className="text-dark w-100 auth__icon-field"
                className={`text-dark w-100 auth__icon-field ${
                  primaryContact?.confirmPassword.error ? "border-danger" : ""
                }`}
                name="confirmPassword"
                value={primaryContact?.confirmPassword.value}
                onChange={(e) =>
                  handleValidatedInputChange(e, setPrimaryContact)
                }
                //required
              />
              {/* <img className="auth__icon" src={viewIcon} /> */}
              {!showConfirmPassword ? (
                <i
                  className="fa fa-eye auth__icon"
                  onClick={showConfirmPasswordToggle}
                />
              ) : (
                <i
                  className="fa fa-eye-slash auth__icon"
                  onClick={showConfirmPasswordToggle}
                />
              )}
            </div>
            {primaryContact?.confirmPassword.error && (
              <small className="text-danger">
                {primaryContact?.confirmPassword.msg}
              </small>
            )}
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default PrimaryContactSection;
