import React from "react";
import { Spinner } from "reactstrap";

const LoadingComponent = () => {
  return (
    <div className="vh-100 d-flex justify-content-center text-dark align-items-center">
      {" "}
      <Spinner> </Spinner>{" "}
    </div>
  );
};

export default LoadingComponent;
