import {
  SET_ADD_CARD_DETAILS_LOADING,
  SET_AUTH_LOADING,
  SET_CARD_DETAILS_LOADING,
  UPDATE_USER_INFO,
} from "../types";

const initialState = {
  loading: false,
  isGettingCardDetails: false,
  isAddingCard: false,
  authUser: {},
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case SET_CARD_DETAILS_LOADING:
      return {
        ...state,
        isGettingCardDetails: payload,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        authUser: {
          ...state.authUser,
          ...payload,
        },
      };

    case SET_ADD_CARD_DETAILS_LOADING:
      return {
        ...state,
        isAddingCard: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
