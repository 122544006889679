import React from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import CustomCheckBox from "../common/CustomCheckBox";

const TypeOfBusiness = ({ typeOfBusiness, setTypeOfBusiness }) => {
  const handleChange = (event) => {
    const value = event.target.name;
    const tempArr = [...typeOfBusiness];

    if (tempArr.includes(value)) {
      // If the value already exists, remove it
      const updatedArr = tempArr.filter((item) => item !== value);
      setTypeOfBusiness(updatedArr);
    } else {
      // If the value doesn't exist, add it
      tempArr.push(value);
      setTypeOfBusiness(tempArr);
    }
  };
  return (
    <Container>
      <Row className="custom-checkbox">
        <Col md={5} sm={6} xs={12}>
          {" "}
          <FormGroup check>
            <CustomCheckBox
              type={typeOfBusiness}
              title={"Beauty Supply Store"}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={5} sm={6} xs={12}>
          <FormGroup check>
            <CustomCheckBox
              type={typeOfBusiness}
              title={"Other"}
              handleChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default TypeOfBusiness;
