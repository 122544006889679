import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { getCities } from "../../store/actions/statesAndCitiesAction";

const PrincipalOwnerSection = ({
  states,
  principal,
  setPrincipal,
  handleValidatedInputChange,
  handleInputChange,
}) => {
  
 
  const handleDetailsChange = (event, index) => {
    let { name, value } = event.target;
    setPrincipal((prevState) => {
      const newOwnerDetails = [...prevState.ownerDetails];
      newOwnerDetails[index][name].value = value;
      newOwnerDetails[index][name].error = false;
      newOwnerDetails[index][name].msg = "";

      return { ...prevState, ownerDetails: newOwnerDetails };
    });
  };
 
  return (
    <Container className="px-md-2 px-0 text-responsive ">
      <h4 className="text-center">Principal Owner Information</h4>
      {principal?.ownerDetails.map((ele, index) => {
        
        return (
          <div className="d-flex ">
            <p className="px-3">{index + 1}. </p>
            <Row key={index}>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <FormGroup>
                  <Label className="auth__label">Name and Title</Label>
                  <Input
                    type="text"
                    placeholder="Name and Title"
                    // className="text-dark"
                    className={`text-dark ${
                      ele.name.error ? "border-danger" : ""
                    }`}
                    name="name"
                    //required
                    value={ele.name.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.name.error && (
                    <small className="text-danger">{ele.name.msg}</small>
                  )}
                </FormGroup>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                {" "}
                <FormGroup>
                  <Label className="auth__label">Home Street</Label>
                  <Input
                    type="text"
                    placeholder="Enter a location"
                    className={`text-dark ${
                      ele.street.error ? "border-danger" : ""
                    }`}
                    name="street"
                    //required
                    value={ele.street.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.street.error && (
                    <small className="text-danger">{ele.street.msg}</small>
                  )}
                </FormGroup>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <FormGroup>
                  <Label className="auth__label">City</Label>
                  <Input
                    type="text"
                    placeholder="Enter your city"
                    className={`text-dark ${
                      ele.city.error ? "border-danger" : ""
                    }`}
                    name="city"
                    //required
                    value={ele.city.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.city.error && (
                    <small className="text-danger">{ele.city.msg}</small>
                  )}
                </FormGroup>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <FormGroup>
                  <Label className="auth__label">State</Label>
                  <Input
                    type="text"
                    placeholder="Enter your state"
                    className={`text-dark ${
                      ele.state.error ? "border-danger" : ""
                    }`}
                    name="state"
                    //required
                    value={ele.state.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.state.error && (
                    <small className="text-danger">{ele.state.msg}</small>
                  )}
                </FormGroup>
            
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <FormGroup>
                  <Label className="auth__label">ZipCode</Label>
                  <Input
                    type="text"
                    placeholder="zipcode"
                    className={`text-dark ${
                      ele.zipCode.error ? "border-danger" : ""
                    }`}
                    name="zipCode"
                    //required
                    value={ele.zipCode.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.zipCode.error && (
                    <small className="text-danger">{ele.zipCode.msg}</small>
                  )}
                </FormGroup>
              </Col>
              <Col xl={2} lg={4} md={4} sm={6} xs={12}>
                <FormGroup>
                  <Label className="auth__label">Phone Or Email</Label>
                  <Input
                    type="text"
                    placeholder="Phone Or Email"
                    className={`text-dark ${
                      ele.phoneOrEmail.error ? "border-danger" : ""
                    }`}
                    name="phoneOrEmail"
                    //required
                    value={ele.phoneOrEmail.value}
                    onChange={(e) => handleDetailsChange(e, index)}
                  />
                  {ele.phoneOrEmail.error && (
                    <small className="text-danger">
                      {ele.phoneOrEmail.msg}
                    </small>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>
        );
      })}
      <p className="text-responsive">
        Do you have another account with Sun Taiyang? Are you applying for
        multiple locations? If so, which store or entity is the main account
        holder?
      </p>
      <Row>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Store or Entity Name</Label>
            <Input
              type="text"
              placeholder="Store or Entity Name"
              className="text-dark"
              name="storeOrEntityName"
              //required
              value={principal?.storeOrEntityName}
              onChange={(e) => handleInputChange(e, setPrincipal)}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <Label className="auth__label">Address</Label>
            <Input
              type="text"
              placeholder="Enter your location"
              className="text-dark"
              name="storeOrEntityAddress"
              //required
              value={principal?.storeOrEntityAddress}
              onChange={(e) => handleInputChange(e, setPrincipal)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default PrincipalOwnerSection;
